import { Button, Card, Layout, message, Modal, PageHeader, Row, Skeleton } from "antd";
import { ReactComponent as GoBackArrow } from '../../img/ic-goback.svg';
import { t } from "i18next";
import React, { createRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { MyTestsCard } from "./MyTestsCard";
import { ChartsCard } from "./ChartsCard";
import { AxiosResponse, AxiosError } from "axios";
import { http } from "../../helpers/http";
import { FailedQuestionsCard } from "./FailedQuestionsCard";
import { TestInfoCard } from "./TestInfoCard";

type TestsMainPageLayoutProps = {
    student: any;
}

export const TestsMainPageLayout = ({ ...props }: TestsMainPageLayoutProps) => {
    const history = useHistory();
    const [isMobile, setIsMobile] = useState(false);
    const [loadingSubStatus, setLoadingSubStatus] = useState(false);
    const [demo, setDemo] = useState(false);
    const [hasPremium, setHasPremium] = useState(false);
    const [demoCounter, setDemoCounter] = useState({
        remaining: 0,
        allowed: 0
    });
    const [isRecuring, setIsRecuring] = useState(false);

    const getDemoCounter = () => {
        setLoadingSubStatus(true);
        http.get(`csdd-test-demo-counter`, { params: { category: props.student.category_id } }).then((res: AxiosResponse) => {
            setLoadingSubStatus(false);
            if (!!res.data.sub) {
                setHasPremium(true);
                setDemo(false);
                setIsRecuring(!!res.data.reccuring_payment);
                return
            }
            history.push('/tests/start-test');
        }).catch((error: AxiosError) => {
            console.log(error);
            message.error(t('errors.error'));
            setLoadingSubStatus(false);
        })
    }

    const cancelSubscribtion = async () => {
        Modal.confirm({
            title: t('csn_tests.cancel_subscription'),
            content: t('csn_tests.you_will_cancel_your_sub'),
            onOk: async () => {
                http.post('cancel-test-category')
                    .then((res: AxiosResponse) => {
                        getDemoCounter();
                        message.success(t('csn_tests.subscription_canceled'));
                    })
                    .catch((error: AxiosError) => {
                        getDemoCounter();
                        message.error(t('errors.error'));
                    });
            },
            onCancel: () => { },
        });

    };

    useEffect(() => {
        getDemoCounter();
    }, [])

    useEffect(() => {
        setIsMobile(window.matchMedia("(max-width: 560px)").matches)
    });

    return <Layout style={{ minHeight: "100vh" }} className="main-content">
        {
            hasPremium
                ? <>
                    <Row
                        className='header-row'
                        style={{
                            background: "#FFF",
                            borderBottom: "2px solid #e7e7e7",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                        }}
                    >
                        <Row>
                            <Button
                                onClick={() => history.goBack()}
                                style={{ fontWeight: "bold" }}
                                icon={<GoBackArrow />}
                            >
                            </Button>
                            <b style={{ padding: "5px 20px" }}>{t('csn_tests.csn_control_test')}</b>
                        </Row>
                        {
                            isRecuring
                                ? <Row style={{ flex: 1, justifyContent: isMobile ? "center" : "end" }}>
                                    <Button onClick={cancelSubscribtion} type="text">{t('csn_tests.cancel_subscription')}</Button>
                                </Row>
                                : <></>
                        }
                    </Row>
                    <MyTestsCard
                        student={props.student}
                        isMobile={isMobile}
                    />
                    <FailedQuestionsCard isMobile={isMobile} />
                    <ChartsCard isMobile={isMobile} />
                    <TestInfoCard />
                </>
                : <Skeleton />
        }
    </Layout>
};