import { Layout, Card, Row, Button, Col, Modal } from "antd"
import { t } from "i18next"
import React, { useEffect, useState } from "react"
import { ReactComponent as GoBackArrow } from '../../img/ic-goback.svg';
import { ReactComponent as EinLogo } from '../../img/logo-ein.svg';
import { useHistory } from "react-router-dom"
//@ts-ignore
import Flag from "react-flags";
import i18n from 'i18next';
import { CountDownTimer } from "../e-learning/common/CountDownTimer";
import moment from "moment";

type TestLayoutProps = {
    isOpen: (state: boolean) => void;
    languages: any;
    children: React.ReactNode;
    timerProps?: {
        seconds: number;
        stopTimer: boolean;
        setTimeIsUp: (timeIsUp: boolean) => void;
        returnTime: (time: string) => void;
        ts: string;
    },
    previewTime?: number;
    exam: any;
    getLanguage: (lang: string) => void;
    goBackUrl?: string;
}


export const TestLayout = ({ ...props }: TestLayoutProps) => {
    const history = useHistory();
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [language, setLanguage] = useState(i18n.language);


    useEffect(() => {
        props.isOpen(true);
        // window.history.pushState(null, document.title, window.location.href);
        // window.addEventListener('popstate', function (event) {
        //     window.history.pushState(null, document.title, window.location.href);
        // });
        return () => {
            props.isOpen(false);
            // window.removeEventListener('popstate', function (event) {
            //     window.history.pushState(null, document.title, window.location.href);
            // });
        }
    }, []);

    useEffect(() => {
        props.getLanguage(language);
    }, [language]);

    useEffect(() => {
        setIsMobile(window.matchMedia("(max-width: 560px)").matches)
        setIsTablet(window.matchMedia("(max-width: 867px)").matches)
    });

    return <Layout>
        {
            isTablet || isMobile
                ? <Row style={{
                    background: "#FFF",
                    padding: 30,
                    margin: "0 0 20px"
                }}>
                    <Col span={24}>
                        <EinLogo style={{ margin: "0 0 10px" }} />
                    </Col>
                    <Col span={24}>
                        <Row style={{ alignItems: "center", marginBottom: 20 }}>
                            <Button
                                onClick={() => props.goBackUrl ? history.push(props.goBackUrl) : history.goBack()}
                                style={{ fontWeight: "bold", marginRight: 20 }}
                                icon={<GoBackArrow />}
                            >
                            </Button>
                            <b >{t('csn_tests.csn_control_test')}</b>
                        </Row>
                    </Col>
                    <Col span={8}>
                        <div
                            className='test-flags'
                            style={{
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            {
                                props.languages.map((lang: any) => {
                                    const iso = lang.iso === "en" ? "gb" : lang.iso;
                                    return <button
                                        key={lang.id}
                                        onClick={() => setLanguage(lang.iso)}
                                    >
                                        <Flag
                                            key={lang}
                                            basePath={`${process.env.REACT_APP_API_URL}storage/flags`}
                                            format="svg"
                                            name={iso}
                                            className={language === lang.iso ? 'active' : ''}
                                        />
                                    </button>
                                })
                            }
                        </div>
                    </Col>
                    <Col span={8}>
                        <Row
                            style={{
                                alignItems: "center",
                                height: "100%"
                            }}
                        >
                            {
                                !isNaN(props.exam?.ind + 1)
                                && <Col style={{ alignItems: "center", minWidth: 100 }}>
                                    <p style={{ fontSize: 14, color: "#676B73", marginBottom: 4, textAlign: "center" }}>{t('csn_tests.question')}</p>
                                    <p style={{ fontSize: 16, color: "#252529", fontWeight: 500, margin: 0, textAlign: "center" }}>{props.exam?.ind + 1} / {props.exam?.questions?.length ?? props.exam?.total}</p>
                                </Col>
                            }
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Row
                            style={{
                                alignItems: "center",
                                height: "100%",
                                padding: 0
                            }}
                        >
                            <Col style={{ alignItems: "center", justifyContent: "center", minWidth: 100 }}>
                                <p style={{ fontSize: 14, color: "#676B73", marginBottom: 4, textAlign: "center" }}>{t('csn_tests.time')}</p>
                                {
                                    props.timerProps === undefined
                                        ? <p style={{ fontSize: 16, color: "#252529", fontWeight: 500, margin: 0, textAlign: "center" }}>{props.previewTime}:00</p>
                                        : <CountDownTimer
                                            seconds={props.timerProps.seconds}
                                            start_time={moment(props.timerProps.ts).format("YYYY-MM-DD HH:mm:ss")}
                                            stopTimer={props.timerProps.stopTimer}
                                            setTimeIsUp={props.timerProps.setTimeIsUp}
                                            returnTime={props.timerProps.returnTime}
                                            style={{ display: "block", textAlign: "center", color: "#252529", fontSize: 16, fontWeight: 500 }}
                                        />
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                : <Row
                    style={{
                        background: "#FFF",
                        borderBottom: "2px solid #e7e7e7",
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "space-between",
                        margin: -20,
                        height: 100,
                        marginBottom: 24,
                        marginTop: 0
                    }}
                >
                    <Row align="middle" style={{ height: "100%" }}>
                        <EinLogo style={{ margin: "0 30px" }} />
                        <Row style={{ borderLeft: "1px solid #E0E6EC", paddingLeft: "30px", alignItems: "center", height: "100%" }}>
                            <Button
                                onClick={() => props.goBackUrl ? history.push(props.goBackUrl) : history.goBack()}
                                style={{ fontWeight: "bold", marginRight: 20 }}
                                icon={<GoBackArrow />}
                            >
                            </Button>
                            <b >{t('csn_tests.csn_control_test')}</b>
                        </Row>
                    </Row>
                    <Row align="middle" justify="end" style={{ height: "100%" }}>
                        <div
                            className='test-flags'
                            style={{
                                padding: "0 30px",
                                borderLeft: "1px solid #E0E6EC",
                                borderRight: "1px solid #E0E6EC",
                                height: "100%",
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            {
                                props.languages.map((lang: any) => {
                                    const iso = lang.iso === "en" ? "gb" : lang.iso;
                                    return <button
                                        key={lang.id}
                                        onClick={() => setLanguage(lang.iso)}
                                    >
                                        <Flag
                                            key={lang}
                                            basePath={`${process.env.REACT_APP_API_URL}storage/flags`}
                                            format="svg"
                                            name={iso}
                                            className={language === lang.iso ? 'active' : ''}
                                        />
                                    </button>
                                })
                            }
                        </div>
                        <Row
                            style={{
                                padding: "0 30px",
                                borderRight: "1px solid #E0E6EC",
                                alignItems: "center",
                                height: "100%"
                            }}
                        >
                            {
                                !isNaN(props.exam?.ind + 1)
                                && <Col style={{ alignItems: "center", minWidth: 100 }}>
                                    <p style={{ fontSize: 14, color: "#676B73", marginBottom: 4, textAlign: "center" }}>{t('csn_tests.question')}</p>
                                    <p style={{ fontSize: 16, color: "#252529", fontWeight: 500, margin: 0, textAlign: "center" }}>{props.exam?.ind + 1} / {props.exam?.questions?.length ?? props.exam?.total}</p>
                                </Col>
                            }
                        </Row>
                        <Row
                            style={{
                                padding: "0 30px",
                                alignItems: "center",
                                height: "100%",
                            }}
                        >
                            <Col style={{ alignItems: "center", justifyContent: "center", minWidth: 100 }}>
                                <p style={{ fontSize: 14, color: "#676B73", marginBottom: 4, textAlign: "center" }}>{t('csn_tests.time')}</p>
                                {
                                    props.timerProps === undefined
                                        ? <p style={{ fontSize: 16, color: "#252529", fontWeight: 500, margin: 0, textAlign: "center" }}>{props.previewTime}:00</p>
                                        : props.timerProps?.seconds
                                        && <CountDownTimer
                                            seconds={props.timerProps.seconds}
                                            start_time={moment(props.timerProps.ts).format("YYYY-MM-DD HH:mm:ss")}
                                            stopTimer={props.timerProps.stopTimer}
                                            setTimeIsUp={props.timerProps.setTimeIsUp}
                                            returnTime={props.timerProps.returnTime}
                                            style={{ display: "block", textAlign: "center", color: "#252529", fontSize: 16, fontWeight: 500 }}
                                        />
                                }
                            </Col>
                        </Row>
                    </Row>
                </Row>
        }
        <Row justify="center">
            {props.children}
        </Row>
    </Layout >
}