import { Button, Card, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { banner as bannerTpl } from "./bannerHtml";
import { http } from "../../helpers/http";
import i18next from "i18next";
import { useHistory } from "react-router-dom";

export const StaticBanner = () => {
    const history = useHistory();
    const [banner, setBanner] = useState(undefined as any);

    const getBanner = () => {
        http.get(`get-banner?type=static`).then((res: any) => {
            if (res.data && Array.isArray(res.data)) {
                setBanner(undefined);
                return;
            }
            setBanner(res.data)
        })
    }

    const navigateToTests = () => {
        history.push(banner?.[`web_link_${i18next.language}`]);
        setBanner(undefined);
    }

    // const setSeen = () => {
    //     http.get(`set-banner-seen/${banner.id}`).then((res: any) => {
    //         navigateToTests();
    //     }).catch((err) => {
    //         navigateToTests();
    //     })
    // };

    useEffect(() => {
        getBanner();
    }, []);

    useEffect(() => {
        console.log("banner", banner)
    }, [banner])

    return !!banner
        ? <Card>
            <div dangerouslySetInnerHTML={{ __html: bannerTpl?.replace("banner-img", process.env.REACT_APP_API_URL + banner?.[`img_static_${i18next.language}`])?.replace("{CONTENT}", banner?.[`banner_static_${i18next.language}`]) }} />
            <Row justify="end">
                <Button
                    type="primary"
                    style={{ backgroundColor: "#482E80", borderColor: "unset", borderRadius: 8 }}
                    onClick={navigateToTests}
                >
                    {banner?.[`static_btn_${i18next.language}`]}
                </Button>
            </Row>
        </Card>
        : <></>
}