import { Button, Card, Col, Layout, Row, Skeleton, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { http } from '../../helpers/http';
// @ts-ignore
import _ from 'lodash';
import { TopInfoCard } from './TopInfoCard';
import { TheoryInfoCard } from './TheoryInfoCard';
import { useTranslation } from 'react-i18next';
import { CheckpointsCard } from '../StudentCheckpoints/CheckpointsCard';
import { PmpCard } from './PmpCard';
import { MyExamsCard } from './MyExamsCard';
import i18next from 'i18next';
import { ShortNotificationsCard } from '../Notifications/ShortNotificationsCard';
import { StaticBanner } from '../components/StaticBanner';

moment.updateLocale('lv', {
    weekdaysMin: ["Sv", "Pr", "Ot", "Tr", "Ce", "Pk", "Se"],
    weekdays: ["Svētdiena", "Pirmdiena", "Otrdiena", "Trešdiena", "Ceturtdiena", "Piektdiena", "Sestdiena"],
    week: {
        dow: 1
    },
    months: ["janvāris", "februāris", 'marts', 'aprīlis', 'maijs', 'jūnijs', 'jūlijs', 'augusts', 'septembris', 'oktobris', 'novembris', 'decembris'],
    monthsShort: ["Janvāris", "Februāris", 'Marts', 'Aprīlis', 'Maijs', 'Jūnijs', 'Jūlijs', 'Augusts', 'Septembris', 'Oktobris', 'Novembris', 'Decembris'],
});

export const MainView = withRouter((props: any) => {
    const { t } = useTranslation();
    const { history, student, theoryExams, refetchExams } = props;
    const [lessonInfo, setLessonInfo] = useState({ last_instructor: null, lessons: null, last_lesson: null } as any);
    const [cities, setCities] = useState([] as any);
    const [loading, setLoading] = useState(true);
    const [studentExamInfo, setStudentExamInfo] = useState({ id: -1 });
    const [activeKey, setActiveKey] = useState("1");
    const [notifications, setNotifications] = useState([] as any);
    const [customNotifications, setCustomNotifications] = useState([] as any);

    const [theoryLessons, setTheoryLessons] = useState([] as any);
    // const [allTheoryLessons, setAlltheoryLessons] = useState([] as any);
    const [foreignLessons, setForeignLessons] = useState([] as any);
    // const [allSignedLessons, setAllSignedLessons] = useState([] as any);
    const [nextTheoryLesson, setNextTheoryLesson] = useState({} as any);
    const [loadedTheory, setLoadedTheory] = useState(false);
    const [haveElearning, setHaveElearning] = useState(false);
    const [studentModules, setStudentModules] = useState([] as any);
    const [passedModules, setPassedModules] = useState([] as any);
    const [notificationsLoading, setNotificationsLoading] = useState(false);

    const fetchNotifications = async () => {
        setNotificationsLoading(true);
        await http.get('notifications-unread').then((response) => {
            setNotifications(response.data)
            if (response.data.length > 0) {
                window.scrollTo({ top: 5, left: 0, behavior: 'smooth' });
            }
        })
        await http.get('get-custom-notifications').then((response) => {
            //console.log(response.data)
            setCustomNotifications(response.data.data);
        })
        setNotificationsLoading(false);
    }

    const getCities = async () => {
        await http.get('/cities').then((response: any) => {
            //console.log(response.data.data)
            setCities(response.data.data)
        })
    }

    const fetchLessons = async () => {
        await http.get('lesson_info')
            .then(result => {
                //console.log("LESSON INFO")
                //console.log(result)
                const { data } = result.data;
                const newLessons = data.lessons?.filter((lesson: any) => {
                    return lesson.status === 'new' && lesson.exam === 0 || lesson.status === "test_drive";
                });
                const doneLessons = data.lessons?.filter((lesson: any) => {
                    return lesson.status === 'done' || lesson.status === "exam_failed" || lesson.status === "exam_passed";
                });
                const canRetryExam = doneLessons?.filter((lesson: any) => {
                    return lesson.status === "exam_failed";
                }).length
                //console.log(canRetryExam)
                const lessonsMissed = data.lessons?.filter((lesson: any) => {
                    return lesson.status !== "new" && lesson.status !== "done" && lesson.status !== "exam_failed" && lesson.status !== "exam_passed" && lesson.status !== "exam";
                });
                const examSlot = data.lessons?.filter((slot: any) => {
                    return slot.status === "exam";
                });
                //console.log(newLessons)
                const upcomingLessons = newLessons.filter((lesson: any) => {
                    return moment(lesson.date, "YYYY-MM-DD").isSameOrAfter(moment().subtract(1, "day"));
                });
                const nextLesson = upcomingLessons.length > 0
                    ? upcomingLessons[0]
                    : undefined
                setLessonInfo({
                    ...data,
                    lessons: data.lessons,
                    newLessons,
                    doneLessons,
                    lessonsMissed,
                    examSlot,
                    nextLesson,
                    canRetryExam
                });
                http.get(`/student-progress/has-exam/${student.id}`).then((resp: any) => {
                    //console.log(resp.data)
                    if (resp.data === "") {
                        setStudentExamInfo({ id: 0 });
                    } else {
                        setStudentExamInfo(resp.data);
                    }
                })
            });
    }

    const fetchTheoryLessons = async () => {
        await http.get(`/student-study-group-attendance/${student.id}`).then((resp: any) => {
            const { attendance, foreign_lessons } = resp.data;
            const attendedLessons = attendance.map((lesson: any) => {
                return {
                    ...lesson.lesson,
                    ...lesson.native,
                    csdd_nr: lesson.csdd
                }
            });

            setTheoryLessons(attendedLessons);
            setForeignLessons(() => {
                const mergedLessons = foreign_lessons.map((lesson: any) => {
                    return {
                        ...lesson.lesson,
                        ...lesson.foreign,
                        csdd_nr: lesson.csdd,
                        paid: lesson.paid
                    }
                })
                //console.log(mergedLessons)
                return mergedLessons.reverse();
            });
        })
    }

    const fetchStudentModules = async () => {
        await http.get(`/student-modules/${student.id}`).then((response: any) => {
            //console.log("Student modules: ", response.data.data)
            setStudentModules(response.data.data);
        })
    };

    const fetchPassedModules = async () => {
        await http.get(`/passed-modules/${student.id}`).then((response: any) => {
            //console.log("passed modules: ", response.data.data)
            setPassedModules(response.data.data)
        })
    };

    function removeLesson() {
        fetchLessons();
    }

    function goToCalendar(id: any, city_id: any = lessonInfo.last_lesson?.city_id) {
        history.push(`/planner/calendar/${id}`, { city_id });
    }

    useEffect(() => {
        //console.log("updated lesson info", studentExamInfo)
        if (studentExamInfo.id >= 0) {
            setLoading(false)
        }
    }, [studentExamInfo]);

    useEffect(() => {
        if (Object.keys(student).length > 0) {
            //console.log("STUDENT", student)
            setHaveElearning(() => {
                if (student.only_study_group.length > 0) {
                    const group = student.only_study_group.find((group: any) => group.category_id === student.category_id)
                    if (group === undefined) return false;
                    return group?.study_type_id === 3 && group?.real_elearning
                } else return false;
            })
            fetchLessons();
            fetchTheoryLessons();
            getCities();
        }
    }, [student]);

    useEffect(() => {
        if (haveElearning) {
            fetchStudentModules();
            fetchPassedModules();
        }
    }, [haveElearning])

    useEffect(() => {
        if (theoryLessons.length > 0) {
            setLoadedTheory(true);
        }
    }, [theoryLessons]);

    useEffect(() => {
        props.refreshBalance();
    }, [])

    useEffect(() => {
        fetchNotifications();
    }, [i18next.language])

    return (loading
        ? <Layout className='skeleton-main-layout' style={{ height: "100vh", width: "100vw" }}><Skeleton /></Layout>
        : <Layout style={{ minHeight: "100vh", width: "100%" }} className="main-content">
            <Row>
                <Col span={24}>
                    {
                        (customNotifications.length > 0 || notifications.length > 0)
                        && <ShortNotificationsCard
                            refreshBalance={() => props.refreshBalance()}
                            notifications={notifications}
                            customNotifications={customNotifications}
                            fetchNotifications={fetchNotifications}
                            loading={notificationsLoading}
                            refetch={fetchTheoryLessons}
                            student={student}
                        />
                    }
                    {/* {
                        notifications.length > 0 || customNotifications.length > 0
                            ? <Card style={{ width: "100%", marginBottom: 20 }} title={t('common.notifications')}>
                                {
                                    customNotifications?.map((notific: any) => {
                                        return <Notification
                                            key={notific.id}
                                            deleteFromList={deleteNotification}
                                            notification={notific}
                                            refreshBalance={() => props.refreshBalance()}
                                            custom
                                        />
                                    })
                                }
                                {notifications?.map((item: any) => {
                                    return <Notification
                                        key={item.id}
                                        deleteFromList={deleteNotification}
                                        notification={item}
                                        refetch={fetchTheoryLessons}
                                        refreshBalance={() => props.refreshBalance()}
                                    />
                                })}
                            </Card>
                            : <></>
                    } */}
                </Col>
            </Row>
            <StaticBanner />
            <Row gutter={20} style={{ marginTop: 20 }}>
                <Col span={24}>
                    <CheckpointsCard self={student} />
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    {
                        loadedTheory
                            ? <TheoryInfoCard
                                // allLessons={allTheoryLessons}
                                attendedLessons={theoryLessons}
                                foreignLessons={foreignLessons}
                                // allSignedLessons={allSignedLessons}
                                student={student}
                                getNotifications={fetchNotifications}
                                getNextLesson={setNextTheoryLesson}
                                cities={cities}
                                refetch={fetchTheoryLessons}
                                haveElearning={haveElearning}
                                eLearningModules={
                                    {
                                        studentModules,
                                        passedModules
                                    }
                                }
                                theoryExams={theoryExams}
                                refetchExams={() => refetchExams()}
                                refreshBalance={() => props.refreshBalance()}
                            />
                            : <></>
                    }
                    {
                        student?.got_pmp
                            ? <></>
                            : <PmpCard student={student} />
                    }
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <TopInfoCard
                        exam={studentExamInfo}
                        canRetryExam={lessonInfo?.canRetryExam}
                        examSlot={lessonInfo?.examSlot.length > 0 && lessonInfo?.examSlot[0]}
                        slot={lessonInfo?.nextLesson}
                        lastLesson={lessonInfo?.last_lesson}
                        activeTab={setActiveKey}
                        toCalendar={() => goToCalendar(lessonInfo?.last_lesson?.instructor_service_id)}
                        city={lessonInfo?.last_lesson?.city_id}
                        student={student}
                    />
                </Col>
            </Row>
            {
                theoryExams.length > 0
                    ? <Row style={{ marginTop: 20 }}>
                        <Col span={24}>
                            <MyExamsCard
                                theoryExams={theoryExams}
                                doomClock={student.doom_clock_csdd}
                                self={student}
                            />
                        </Col>
                    </Row>
                    : <></>
            }
            {
                ((i18next.language === "lv" || i18next.language === "ru") && (student.category_id === 1 || student?.category_id === 3))
                    ? <Row style={{ paddingTop: 20 }}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Card>
                                <h3 style={{ marginBottom: 20 }}><b>Kā iegūt autovadītāja apliecību</b></h3>
                                <video
                                    style={{ width: "100%", height: "auto" }}
                                    controls
                                    src={
                                        student?.category_id === 1
                                            ? "https://einsteins.lv/storage/media/853/Einsteins_Boat_labots.mp4"
                                            : student?.category_id === 3
                                                ? "https://einsteins.lv/storage/media/876/einsteins-c-kategorija.mp4"
                                                : undefined
                                    }
                                />
                            </Card>
                        </Col>
                    </Row>
                    : <></>
            }
        </Layout>
    )
})