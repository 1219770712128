import React, { useEffect, useMemo, useState } from "react";
import { http } from "../../helpers/http";
import Chart from "react-google-charts";
import { useTranslation } from "react-i18next";
import { message, Row } from "antd";

type ChartsCardProps = {
    isMobile: boolean;
}

export const ChartsCard = ({ ...props }: ChartsCardProps) => {
    const { t } = useTranslation();
    const [data, setData] = useState(null as any);
    const [maxValue, setMaxValue] = useState(30);
    const [counters, setCounters] = useState({
        noOrOne: 0,
        two: 0,
        three: 0
    });
    const chartColumnsSettings = [
        "",
        "",
        { role: "style" },
    ]

    const getTests = () => {
        http.get('csdd-test-mistake-counter').then((response) => {
            setCounters({
                noOrOne: response.data.no_or_one_mistakes,
                two: response.data.two_mistakes,
                three: response.data.three_mistakes
            });
            setMaxValue(response.data.max_number ?? 30)
            setData(() => {
                const tempData = response.data.data.map((item: any, index: number) => {
                    return [
                        "",
                        item.stat_number,
                        item.mistakes >= 3
                            ? "#CF1726"
                            : item.mistakes === 2
                                ? "#EBB447"
                                : "#29A352"
                    ]
                })
                console.log("data structure", tempData);
                return tempData;
            });
        }).catch((error) => {
            if (error.response) {
                console.log(error.response.data.message);
                message.error(t('errors.error'));
            }
        });
    }

    useEffect(() => {
        getTests();
    }, [])

    return <div className="shadow-card" style={{ padding: 24, height: 317, marginBottom: 24 }}>
        <h2 className="heading2" style={{ marginBottom: 4 }}>
            {t('csn_tests.tests_statistics')}
        </h2>
        <Row style={{ marginBottom: 16 }}>
            <Row align="middle" style={{ marginRight: 20 }}>
                <div style={{ width: 8, height: 8, backgroundColor: "#29A352", marginRight: 8 }} />
                <p className="paragraph" style={{ marginBottom: 0, marginRight: 8, fontSize: 15 }}>{t('csn_tests.zero_or_one_mistake')}</p>
                <p className="paragraph" style={{ marginBottom: 0, marginRight: 8, fontSize: 15 }}>({counters.noOrOne})</p>
            </Row>
            <Row align="middle" style={{ marginRight: 20 }}>
                <div style={{ width: 8, height: 8, backgroundColor: "#EBB447", marginRight: 8 }} />
                <p className="paragraph" style={{ marginBottom: 0, marginRight: 8, fontSize: 15 }}>{t('csn_tests.two_mistakes')}</p>
                <p className="paragraph" style={{ marginBottom: 0, marginRight: 8, fontSize: 15 }}>({counters.two})</p>
            </Row>
            <Row align="middle" style={{ marginRight: 20 }}>
                <div style={{ width: 8, height: 8, backgroundColor: "#CF1726", marginRight: 8 }} />
                <p className="paragraph" style={{ marginBottom: 0, marginRight: 8, fontSize: 15 }}>{t('csn_tests.three_or_more_mistakes')}</p>
                <p className="paragraph" style={{ marginBottom: 0, marginRight: 8, fontSize: 15 }}>({counters.three})</p>
            </Row>
        </Row>
        {
            (data && data.length > 0)
            && <div style={{ position: "relative", overflowX: "auto", maxWidth: "90vw" }}>
                <div>
                    <Chart
                        chartType="ColumnChart"
                        width={props.isMobile ? 600 : "100%"}
                        height="191px"
                        data={[chartColumnsSettings, ...data]}
                        options={{
                            legend: { position: "none" },
                            chartArea: { width: "95%", height: "80%" },
                            vAxis: {
                                maxValue,
                                minValue: 0,
                            }
                        }}
                    />
                </div>
            </div>
        }
    </div>
}