import { Card } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

export const TestInfoCard = () => {
    const { t } = useTranslation();

    return <Card
        className="shadow-card"
        bodyStyle={{
            padding: 24,
            marginBottom: 24
        }}
    >
        <h2 className="heading2">{t('csn_tests.csn_control_test')}</h2>
        <p className="paragraph" style={{ marginBottom: 20 }}>{t('csn_tests.what_is_csn_test_info')}</p>
        <div
            style={{
                paddingLeft: 20,
                borderLeft: "2px solid #059DC1",
                marginBottom: 20
            }}
        >
            <h3 className="heading3">
                {t('csn_tests.pay_attention')}
            </h3>
            <p className="paragraph" style={{ margin: 0 }}>{t('csn_tests.pay_attention_info')}</p>
        </div>
    </Card>
}