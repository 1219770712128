import { Col, DatePicker, Radio, Row, Select, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { AxiosError, AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";

type MedicalFormProps = {
    service: any;
    setDisabled: (state: boolean) => void;
    submit: boolean;
    destroyModal: () => void;
    refresh: () => void;
    refreshBalance: () => void;
}

export const MedicalForm = ({ service, setDisabled, submit, destroyModal, refresh, refreshBalance }: MedicalFormProps) => {
    const { t } = useTranslation();
    const [cities, setCities] = useState([] as any);
    const [city, setCity] = useState(1 as number | undefined);
    const [allowedCities, setAllowedCities] = useState([] as any);
    const [dateTime, setDateTime] = useState({
        date: null as moment.Moment | null,
        time_period: ""
    });

    const getAllowedCities = () => {
        http.get(`get-study-service-type/${3}`).then((res: AxiosResponse) => {
            setAllowedCities(res.data.data.type_cities)
        })
    }

    const options = [
        { label: '14:30 - 15:30', value: '14:30 - 15:30' },
        { label: '15:45 - 16:45', value: '15:45 - 16:45' },
        { label: '17:00 - 18:15', value: '17:00 - 18:15' },
    ];

    const getCities = () => {
        http.get(`cities`).then((res: AxiosResponse) => {
            setCities(res.data.data)
        })
    };

    const submitMedical = async () => {
        const notExtra = service.services.some((service: any) => {
            if (!service.hasOwnProperty("pivot")) return true;
            if (!!!service.pivot.paid) {
                return true
            }
        })
        const services = service.services
            .filter((item: any) => {
                if (!notExtra) return true;
                if (!item.hasOwnProperty("pivot")) return true;
                else {
                    return !!!item.pivot.paid
                }
            })
            .map((item: any) => {
                if (item.study_group_service_type_id === 7) {
                    return {
                        service_id: item.id,
                        city: city,
                        // date: moment(dateTime.date).format("YYYY-MM-DD"),
                        // time_period: dateTime.time_period,
                        extra: notExtra ? 0 : 1
                    }
                } else {
                    return {
                        service_id: item.id,
                        extra: notExtra ? 0 : 1
                    }
                }

            })
        http.post(`add-service-for-self`, { services }).then((res: AxiosResponse) => {
            refresh();
            refreshBalance();
            message.success(t('notification.service_bought_sucessfully'))
        }).catch((err: AxiosError) => {
            const msg = err.response?.data.msg
            message.error(msg)
        })
        return true
    }

    useEffect(() => {
        getCities();
    }, []);

    useEffect(() => {
        if (submit) {
            submitMedical().then(() => { destroyModal() });
        }
    }, [submit]);

    useEffect(() => {
        if (
            // dateTime.date === undefined
            // || dateTime.date === null
            // || dateTime.time_period.length === 0
            // || 
            city === undefined
        ) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [dateTime, city])

    useEffect(() => {
        getAllowedCities();
    }, [service])

    return <Row gutter={[20, 30]}>
        <Col span={12}>
            <p style={{ marginBottom: 10 }}>{t('common.city')}</p>
            <Select
                loading={allowedCities.length === 0}
                value={city}
                style={{ width: 200, marginTop: 5 }}
                onChange={(value: any) => setCity(value)}
                onClick={e => e.stopPropagation()}
            >
                {
                    cities.filter((city: any) => allowedCities?.some((id: any) => id === city.id)).map((city: any) => {
                        return <Select.Option key={city.id} value={city.id}>{city.value}</Select.Option>
                    })
                }
            </Select>
        </Col>
        {/* <Col span={12}>
            <p style={{ marginBottom: 10 }}>{t('common.date')} ({t('common.approximately')})</p>
            <DatePicker
                value={dateTime.date}
                disabledDate={(date: any) => {
                    return date.isBefore(moment().add(1, "week"))
                }}
                onChange={value => {
                    setDateTime((prev: any) => {
                        return {
                            ...prev,
                            date: value
                        }
                    })
                }}
            />
        </Col>
        <Col span={24}>
            <p style={{ marginBottom: 10 }}>{t('common.desired_time')} ({t('common.approximately')})</p>
            <div>
                <Radio.Group
                    options={options}
                    onChange={e => {
                        setDateTime((prev: any) => {
                            return {
                                ...prev,
                                time_period: e.target.value
                            }
                        })
                    }}
                    // value={value1}
                    optionType="button"
                    buttonStyle="solid"
                />
            </div>
        </Col> */}
    </Row>
}