import { Button, Card, Layout, message, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { http } from "../../../helpers/http";
import { CountDownTimer } from "../../e-learning/common/CountDownTimer";

const centered = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
} as any;

export const TheoryExamStartPage = (props: any) => {
    const { t } = useTranslation();
    const { self } = props;
    const history = useHistory();
    const { hash } = useParams() as any;
    const [loading, setLoading] = useState(false);
    const [mobile, setMobile] = useState(false);
    const [examTime, setExamTime] = useState(undefined as number | undefined);
    const [timeIsUp, setTimeIsUp] = useState(false);
    const [examStartTime, setExamStartTime] = useState(undefined as undefined | string);
    const [examDuration, setExamDuration] = useState(undefined as undefined | number);
    const [exam, setExam] = useState(undefined as any);

    const startExam = async () => {
        setLoading(true);
        await http.post(`make-csdd-exam`, { hash }).then((response: any) => {
            console.log(response)

            // if (response.data.data === null) {
            //     message.error("Nav pabeigtas visas moduļa nodaļas!")
            //     setLoading(false);
            //     setTimeout(() => history.goBack(), 2000)
            //     return;
            // }
            // if (response.data.data.exam.passed) {
            //     setExamPassed(true);
            //     return;
            // }
            window.sessionStorage.removeItem('questions');
            window.sessionStorage.removeItem('ind');
            window.sessionStorage.removeItem('exam');
            window.sessionStorage.removeItem('results');
            history.replace('/theory-exam-instance', { data: { ...response.data.data, hash, category: self.category, mainTimer: examTime! - 2 } });
            setLoading(false);
        });
    };

    const fetchData = async () => {
        http.post(`get-theory-exam-by-hash`, { hash }).then((response: any) => {
            const examObject = response.data;
            setExam(examObject)
            setExamStartTime(moment(`${examObject?.exam_date} ${examObject?.time_from}`).subtract(5, "minutes").format("YYYY-MM-DD HH:mm:ss"))
            setExamDuration(moment(examObject?.time_to, "HH:mm:ss").diff(moment(examObject?.time_from, "HH:mm:ss"), "seconds") + 1500)
            // setExamStartTime(response.data.data.exam_start_time);
        });
        http.post(`check-csdd-exam-active`, { hash })
        // await http.get(`learning-modules-list/${self.category_id}/${mid}`).then((response: any) => {
        //     setModule(response.data.data);
        //     //console.log(response)
        //     if (response.data.data?.passed) {
        //         setExamPassed(true)
        //         http.post(`/set-passed-module/${module.hash_id}`, { skip: 1 });
        //     }
        // });
    };

    useEffect(() => {
        window.history.pushState({ name: "browserBack" }, "on browser back click", window.location.href);
        const onBackAction = (event: any) => {
            event.preventDefault();
            history.push('/planner')
        }
        window.addEventListener('popstate', onBackAction, false);
        fetchData();
        setMobile(window.matchMedia("(max-width: 767px)").matches)

        window.addEventListener("resize", () => {
            setMobile(window.matchMedia("(max-width: 767px)").matches)
        })
        return () => {
            window.removeEventListener('popstate', onBackAction, false);
            window.removeEventListener("resize", () => {
                setMobile(window.matchMedia("(max-width: 767px)").matches)
            })
        }
    }, []);

    return <Layout className="main-content">
        <Card className="module-exam-card">
            <Row justify="space-between">
                <h2>Skolas teorijas eksāmens.</h2>
                {
                    (examStartTime !== undefined && examDuration !== undefined)
                    && <CountDownTimer
                        seconds={examDuration}
                        start_time={examStartTime}
                        // stopTimer={stopTimer}
                        setTimeIsUp={(timeIsUp: boolean) => setTimeIsUp(timeIsUp)}
                        returnTime={(time: string) => {
                            const timer = moment(time, time.length > 5 ? "HH:mm:ss" : "mm:ss");
                            const secs = timer.hours() * 3600 + timer.minutes() * 60 + timer.seconds();
                            setExamTime(secs);
                        }}
                    />
                }
            </Row>
            <div
                className="exam-status-container"
            >
                {/* {
                    examPassed && <h2 style={{ display: mobile ? "block" : "none" }}>{t('e_learning.exam_is_passed')}</h2>
                } */}

                <Row>
                    {/* <div className="status-wrap">
                        <p>{t('e_learning.status')}</p>
                        <b className={`${module?.passed ? "completed" : "not-completed"}`}>
                            {module?.passed ? t('e_learning.passed') : t('e_learning.failed')}
                        </b>
                    </div> */}
                    {/* <div className="status-wrap">
                        <p>{t('e_learning.tries_left')}</p>
                        <b>{module?.attempts_left} {module?.attempts_left === 1 ? t('e_learning.try') : t('e_learning.tries')}</b>
                    </div> */}
                    <div className="status-wrap">
                        <p>{t('e_learning.questions_count')}</p>
                        <b>{self?.category?.exam_questions_count} {t('e_learning.questions')}</b>
                    </div>
                    <div className="status-wrap">
                        <p>{t('e_learning.time_limit')}</p>
                        <b>{self?.category?.exam_time_limit} {t('common.minutes')}</b>
                    </div>
                    <div className="status-wrap">
                        <p>{t('e_learning.permited_wrong_answers')}</p>
                        <b>{self?.category?.exam_max_errors} {self?.category?.exam_max_errors === 1 ? t('errors.error') : t('errors.errors')}</b>
                    </div>
                    <div className="status-wrap"></div>
                </Row>
                {
                    false
                        ? <h2 style={mobile ? { textAlign: "center", display: "none" } : centered}>{t('e_learning.exam_is_passed')}</h2>
                        // : module?.attempts_left === 0
                        : false
                            ? <h2 style={centered}>{t('e_learning.tries_count_is')} 0</h2>
                            : !!!exam?.already_done || exam === undefined
                                ? <button
                                    className={`btn-start-test ${timeIsUp || loading || examStartTime === undefined || examTime === undefined || !!exam?.already_done ? 'disabled' : ''}`}
                                    onClick={startExam}
                                    disabled={timeIsUp || loading || examStartTime === undefined || examTime === undefined || !!exam?.already_done}
                                >
                                    {t('e_learning.start_test')}
                                </button>
                                : <h2 style={centered}>Tests jau ir izpildīts</h2>
                }
            </div>
        </Card>
    </Layout>
}