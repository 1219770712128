import { Row, Col, Button, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ReactComponent as FailedIcon } from "../../img/ic-warning-red-circle.svg";
import { http } from "../../helpers/http";
import { ProgressBarFailedQuestions } from "./ProgressBarFailedQuestions";

type FailedQuestionsCardProps = {
    isMobile: boolean;
}

export const FailedQuestionsCard = ({ ...props }: FailedQuestionsCardProps) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [period, setPeriod] = useState(7 as 7 | 14 | undefined);
    const [failures, setFailures] = useState(0);
    const [minFailures, setMinFailures] = useState(15);
    const [progress, setProgress] = useState(0);

    const getFailures = () => {
        http.get(`csdd-test-unique-mistake-counter`, {
            params: {
                period
            }
        }).then((response) => {
            setFailures(response.data.data);
            setMinFailures(response.data.min_number);
            setProgress(response.data.data <= response.data.min_number ? response.data.data / response.data.min_number * 100 : 100)
        }).catch((error) => {
            console.log(error);
            message.error(t("errors.error"));
        });
    }

    useEffect(() => {
        getFailures();
    }, [period]);

    return <div className="shadow-card" style={{ padding: 24, marginBottom: 24 }}>
        <Row justify="space-between">
            <Col span={24} lg={18}>
                <h2 className="heading2" style={{ marginBottom: 4 }}>
                    {t('csn_tests.failed_questions_test')}
                </h2>
                <p className="paragraph" style={{ margin: 0, marginBottom: 16 }}>
                    {t("csn_tests.failed_questions_test_description", { count: minFailures })}
                </p>
                {
                    props.isMobile
                    && <ProgressBarFailedQuestions failures={failures} progress={progress} />
                }
                {
                    failures < minFailures
                    && <Row style={{ marginBottom: 16, flexWrap: "nowrap" }}>
                        <FailedIcon style={{ marginRight: 8 }} />
                        <p className="paragraph" style={{ color: "#C02227", margin: 0 }}>{t("csn_tests.you_have_not_accumulated_enough_mistakes", { count: failures, min: minFailures })}</p>
                    </Row>
                }
                <Row>
                    <Col span={24}>
                        <p className="paragraph" style={{ marginBottom: 8 }}>{t("csn_tests.choose_period")}</p>
                        <Row justify="space-between">
                            <div>
                                <Row>
                                    <Button
                                        style={{ marginRight: 4, marginBottom: 4, width: props.isMobile ? "100%" : "auto" }}
                                        className={`btn-ghost-tests-filter ${period === 7 ? "active" : ""}`}
                                        onClick={() => setPeriod(7)}
                                    >
                                        {t("csn_tests.last_count_days", { count: 7 })}
                                    </Button>
                                    <Button
                                        style={{ marginRight: 4, marginBottom: 4, width: props.isMobile ? "100%" : "auto" }}
                                        className={`btn-ghost-tests-filter ${period === 14 ? "active" : ""}`}
                                        onClick={() => setPeriod(14)}
                                    >
                                        {t("csn_tests.last_count_days", { count: 14 })}
                                    </Button>
                                    <Button
                                        style={{ marginRight: 4, marginBottom: 4, width: props.isMobile ? "100%" : "auto" }}
                                        className={`btn-ghost-tests-filter ${period === undefined ? "active" : ""}`}
                                        onClick={() => setPeriod(undefined)}
                                    >
                                        {t("csn_tests.all_period")}
                                    </Button>
                                </Row>
                            </div>
                            <Button
                                type="primary"
                                className={`btn-start-csn-test`}
                                style={{ width: props.isMobile ? "100%" : "auto" }}
                                onClick={() => history.push({ pathname: "/tests/start-test", state: { type: "error_correction" } })}
                                disabled={failures < 10}
                            >
                                {t('csn_tests.start_test')}
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Col>
            {
                !props.isMobile
                && <Col span={24} lg={6}>
                    <ProgressBarFailedQuestions failures={failures} progress={progress} />
                </Col>
            }
        </Row>
    </div>
}