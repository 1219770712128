import { Button, Card, message, Modal } from "antd";
import React, { useState } from "react";
import { ReactComponent as WarningIcon } from "../../img/warning_sircle_exclamation_yellow.svg";
import { ReactComponent as StarListIcon } from "../../img/list-style-star.svg";
import { ReactComponent as CloseModalIcon } from "../../img/close-modal-circle-icon.svg";
import { useTranslation } from "react-i18next";
import { AxiosResponse, AxiosError } from "axios";
import { http } from "../../helpers/http";
import { useHistory } from "react-router-dom";

type BuySubModalProps = {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    price: string;
    discountedPrice?: string;
    checkIfSubscribed: () => void;
    refreshBalance: () => void;
}

export const BuySubModal = ({ ...props }: BuySubModalProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const buySubscribtion = () => {
        setLoading(true);
        http.post(`buy-csdd-test-subscription`).then((res: AxiosResponse) => {
            console.log(res)
            if (res?.data?.data?.reason) {
                if (!!!res?.data?.data?.success) {
                    if (res?.data?.data?.reason === "not_enough_balance") {
                        message.error(t('common.not_enough_balance'));
                    } else {
                        message.error(res.data.data.reason);
                    }
                }
            }
            if (res?.data?.data?.success) {
                // props.setVisible(false);

            }
            props.setVisible(false);
            props.checkIfSubscribed();
            setLoading(false);
            props.refreshBalance();
        }).catch((error: AxiosError) => {
            console.log(error);
            message.error(t('errors.error'));
            setLoading(false);
            props.refreshBalance();
            props.setVisible(false);
        })
    }

    const toTheBeginingHandler = () => {
        props.setVisible(false);
        history.push("/planner");
    }

    return <Modal
        visible={props.visible}
        footer={null}
        bodyStyle={{ padding: 48 }}
        closeIcon={<CloseModalIcon style={{ marginTop: 24, marginRight: 24 }} />}
        onCancel={() => props.setVisible(false)}
    >
        <WarningIcon style={{ marginBottom: 20 }} />
        <h2 className="heading2" style={{ marginBottom: 20 }}>{t('csn_tests.demo_expired_warning')}</h2>
        <p className="bold-paragraph" style={{ color: "#7434FF", marginBottom: 8 }}>{t(`csn_tests.subscription_advantages_heading`)}</p>
        <ul className="sub-modal-list">
            <li><div><StarListIcon style={{ marginRight: 8, height: 22, width: 16 }} /></div>{t(`csn_tests.advantage_1`)}</li>
            <li><div><StarListIcon style={{ marginRight: 8, height: 22, width: 16 }} /></div>{t(`csn_tests.advantage_2`)}</li>
            <li><div><StarListIcon style={{ marginRight: 8, height: 22, width: 16 }} /></div>{t(`csn_tests.advantage_3`)}</li>
            <li><div><StarListIcon style={{ marginRight: 8, height: 22, width: 16 }} /></div>{t(`csn_tests.advantage_4`)}</li>
        </ul>
        <p className="bold-paragraph" style={{ color: "#7434FF", marginBottom: 0, fontWeight: 400 }}>{t('csn_tests.only_for') + " "}
            <span style={{ fontWeight: 700 }}>
                {
                    props.discountedPrice
                        ? <span style={{ textDecoration: "line-through", color: "#000", marginRight: 8, fontSize: 14 }}>{props.price} EUR</span>
                        : null
                }
                {props.discountedPrice ? props.discountedPrice : props.price} EUR
            </span> {t('csn_tests.a_month')}
        </p>
        <p className="paragraph-xs" style={{ marginBottom: 32 }}>{t('csn_tests.you_can_cancel_anytime')}</p>
        <Button
            type="primary"
            className={`btn-start-csn-test`}
            style={{ backgroundColor: "#7434FF", marginBottom: 10 }}
            onClick={buySubscribtion}
            disabled={loading}
            loading={loading}
        >
            {t('csn_tests.buy_subscription')}
        </Button>
        <Button
            type="primary"
            className={`btn-start-csn-test`}
            style={{ backgroundColor: "#F0F2F5", color: "#000" }}
            onClick={toTheBeginingHandler}
        >
            {t('csn_tests.return')}
        </Button>
    </Modal>
}