import { Button, Col, Row, Table } from "antd";
import { ColumnType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { http } from "../../helpers/http";
import { AxiosError, AxiosResponse } from "axios";
import moment from "moment";
import { timePartIsSingularCheck } from "../../helpers/timePartIsSingularCheck";
import { ReactComponent as FailedIcon } from "../../img/ic-warning-red-circle.svg";
import { ReactComponent as SuccessIcon } from "../../img/checkmark-circle-green.svg";

type MyTestsCardProps = {
    student: any;
    isMobile: boolean;
}

export const MyTestsCard = ({ ...props }: MyTestsCardProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [examsHistory, setExamsHistory] = useState<any[]>([]);
    const [showAll, setShowAll] = useState(false);
    const [loading, setLoading] = useState(false);
    const [overallStats, setOverallStats] = useState({
        total_tests: 0,
        failed_tests: 0,
        probability: 0,
    });


    const getTestsHistory = () => {
        setLoading(true);
        const studentCategory = props.student.category_id;
        http.get('csdd-test-history', {
            params: {
                category: studentCategory,
                limit: showAll ? undefined : 3,
            }
        }).then((response: AxiosResponse) => {
            console.log(response.data);
            setExamsHistory(response.data.data[0]);
            setLoading(false);
        }).catch((error: AxiosError) => {
            console.log(error);
            setLoading(false);
        });
    }

    const getStats = () => {
        http.post(`csdd-test-totals`).then((response: AxiosResponse) => {
            setOverallStats(response.data.data);
        }).catch((error: AxiosError) => {
            console.log(error);
        });
    }

    useEffect(() => {
        getStats();
    }, []);

    useEffect(() => {
        getTestsHistory();
    }, [showAll]);

    const columns = [
        {
            title: t('common.date_and_time'),
            dataIndex: 'created_at',
            render: (dateTime: string) => {
                return <p className="bold-paragraph" style={{ margin: 0, fontSize: 15 }}>{moment(dateTime).format('DD.MM.YYYY HH:mm')}</p>
            },
        },
        {
            title: t('csn_tests.questions_count'),
            dataIndex: 'question_counter',
            render: (questions: number, record: any) => {
                return record.time_ended
                    ? <b>-</b>
                    : <p className="paragraph" style={{ margin: 0, fontSize: 15 }}>
                        <b style={{ color: "#252529" }}>
                            {`${questions - record.mistakes}`}
                        </b>
                        {` / ${questions}`}
                    </p>
            },
        },
        {
            title: t('csn_tests.mistakes_count'),
            dataIndex: 'mistakes',
            render: (mistakes: number, record: any) => {
                return record.time_ended
                    ? <b>-</b>
                    : <p className="paragraph" style={{ margin: 0, fontSize: 15 }}>
                        {mistakes} {timePartIsSingularCheck(mistakes) ? t('csn_tests.mistake') : t('csn_tests.mistakes')}
                    </p>
            },
        },
        {
            title: t('csn_tests.test_time'),
            dataIndex: 'elapsed_time',
            render: (time: string, record: any) => {
                return record.time_ended
                    ? <b>{t('csn_tests.time_ended')}</b>
                    : moment(time, 'HH:mm:ss').format('mm:ss');
            },
        },
        {
            title: t('csn_tests.status'),
            dataIndex: 'passed',
            render: (status: 1 | 0) => {
                return <p className="paragraph" style={{ margin: 0, fontSize: 15 }}>
                    {
                        !!status
                            ? <span className="bold-paragraph" style={{ color: "#2C9C3B", display: "flex", alignItems: "center" }}>
                                <SuccessIcon style={{ marginRight: 8 }} />{t('csn_tests.passed')}
                            </span>
                            : <span className="bold-paragraph" style={{ color: "#CC3326", display: "flex", alignItems: "center" }}>
                                <FailedIcon style={{ marginRight: 8 }} />{t('csn_tests.failed')}
                            </span>
                    }
                </p>
            },
        }
    ] as ColumnType<any>[];

    return <div className="shadow-card" style={{ padding: 24, marginBottom: 24 }}>
        <Row justify="space-between" style={{ marginBottom: 20 }}>
            <Col>
                <h2 className="heading2" style={{ marginBottom: 4 }}>
                    {t('csn_tests.my_completed_tests')}
                </h2>
                <p className="paragraph">

                </p>
            </Col>
            <Button
                type="primary"
                className={`btn-start-csn-test`}
                style={{ width: "auto" }}
                onClick={() => history.push("/tests/start-test")}
            >
                {t('csn_tests.start_test')}
            </Button>
        </Row>
        <Row gutter={8} style={{ marginBottom: 10 }}>
            <Col span={24} md={8}>
                <div style={{
                    borderRadius: 2,
                    border: "1px solid #D8DCE6",
                    boxShadow: "0px 1px 2px 0px rgba(64, 85, 128, 0.08)",
                    padding: 20,
                    marginBottom: 10
                }}>
                    <h1 className="heading-xl" style={{ fontWeight: 900, lineHeight: "36px", marginBottom: 0 }}>{overallStats.total_tests}</h1>
                    <p className="paragraph" style={{ marginBottom: 0 }}>{t('csn_tests.done_tests')}</p>
                </div>
            </Col>
            <Col span={24} md={8}>
                <div style={{
                    borderRadius: 2,
                    border: "1px solid #D8DCE6",
                    boxShadow: "0px 1px 2px 0px rgba(64, 85, 128, 0.08)",
                    padding: 20,
                    marginBottom: 10
                }}>
                    <h1 className="heading-xl" style={{ fontWeight: 900, lineHeight: "36px", marginBottom: 0 }}>{overallStats.total_tests - overallStats.failed_tests}</h1>
                    <p className="paragraph" style={{ marginBottom: 0 }}>{t('csn_tests.succeed_tests')}</p>
                </div>
            </Col>
            <Col span={24} md={8}>
                <div style={{
                    borderRadius: 2,
                    border: "1px solid #D8DCE6",
                    boxShadow: "0px 1px 2px 0px rgba(64, 85, 128, 0.08)",
                    padding: 20,
                    marginBottom: 10
                }}>
                    <h1 className="heading-xl" style={{ fontWeight: 900, lineHeight: "36px", marginBottom: 0, color: overallStats?.probability < 95 ? "#CC3326" : "#2C9C3B" }}>{overallStats.probability}%</h1>
                    <p className="paragraph" style={{ marginBottom: 0 }}>{t('csn_tests.success_probability')}</p>
                </div>
            </Col>
        </Row>
        <div style={{ maxWidth: "90vw", overflowX: "auto" }}>
            <Table
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={examsHistory}
                pagination={false}
                loading={loading}
                style={{ marginBottom: 20 }}
            />
        </div>
        <Button
            type="text"
            onClick={() => setShowAll(!showAll)}
            style={{ color: "#059DC1" }}
        >
            {
                showAll
                    ? t('csn_tests.show_less')
                    : t('csn_tests.show_more')
            }
        </Button>
    </div>
}