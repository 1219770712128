import { Row } from "antd";
import React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import GradientSVG from "./GradientForProgress";
import { useTranslation } from "react-i18next";
import { ReactComponent as StarActive } from "../../img/star-active.svg";
import { ReactComponent as StarActiveWithDots } from "../../img/star-active-with-dots.svg";
import { ReactComponent as StarInactiveWithDots } from "../../img/star-inactive-with-dots.svg";

type ProgressBarFailedQuestionsProps = {
    failures: number;
    progress: number;
}

export const ProgressBarFailedQuestions = ({ ...props }: ProgressBarFailedQuestionsProps) => {
    const { t } = useTranslation();

    return <div>
        <Row justify="center">
            <div
                style={{
                    width: 160,
                    height: 160,
                    position: "relative",
                }}
            >
                <GradientSVG />
                <CircularProgressbarWithChildren
                    value={props.progress}
                    strokeWidth={8}
                    circleRatio={0.63}
                    styles={{
                        root: {
                            transform: "rotate(-113deg)",
                        },
                        path: { stroke: `url(#hello)` },
                        trail: { stroke: "#F0F2F5" },
                    }}

                >
                    <div style={{ position: "absolute", top: 20 }}>
                        {
                            props.progress === 0
                                ? <StarInactiveWithDots />
                                : <StarActiveWithDots />
                        }
                    </div>
                </CircularProgressbarWithChildren>
            </div>
        </Row>
        <div style={{ position: "relative", top: -20 }}>
            <h1 className="heading-xl" style={{ fontWeight: 900, lineHeight: "36px", color: "#7434FF", margin: 0, textAlign: "center" }}>{props.failures}</h1>
            <p className="paragraph" style={{ margin: 0, textAlign: "center" }}>{t("csn_tests.accumulated_questions")}</p>
        </div>
    </div>
}