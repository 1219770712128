import { Row, Input, Col, Button, Radio, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
//@ts-ignore
import { useBeforeunload } from "react-beforeunload";
import { AxiosError } from 'axios';
import { useHistory } from 'react-router';
import { http } from "../../../helpers/http";
import { ReactComponent as ArrowLeft } from '../../../img/arrow-back-csn.svg';
import { ReactComponent as ArrowRight } from '../../../img/arrow-fwd-csn.svg';

export const TheoryExamQuestion = (props: any) => {
    const history = useHistory();
    const locale = props.language ?? i18n.language;
    const { t } = useTranslation();
    const { question, exam, review, mobile, ind, csn } = props;
    const [selectedAnswer, setAnswer] = useState(undefined as any);
    const [zoomIn, setZoomIn] = useState(false);

    const actionHandler = async () => {
        props.nextQuestion(selectedAnswer);
        setAnswer(undefined);
    }

    const goToIndex = (index: number) => {
        props.nextQuestion(index);
    }

    const hackermanMessage = () => {
        Modal.confirm({
            title: "Kļūda!",
            content: <div>
                <b>Iesniegto atbilžu daudzums pārsniedz jautājumu skaitu!<br />Lūgums sazināties ar administrāciju!<br /> Jūs tiksiet pārvirzīts uz Online apmācības lapu.</b>

            </div>,
            onOk: () => {
                history.push("/e-learning")
            },
            onCancel: () => {
                history.push("/e-learning")
            }
        })
    }

    const logReactError = (msg: string) => {
        http.post(`log-data`, { msg }).then(() => {
            history.goBack();
        })
    }

    useBeforeunload((event: any) => {
        event.preventDefault();
    });

    try {
        return <div
            className="exam-question-card"
        >
            {
                // timeIsUp
                false
                    ? <Row>
                        <Col span={24}>
                            {/* <Row justify="center" style={{ marginBottom: 30 }}>
                                <div className="icon-round-wrap">
                                    <IconClock />
                                </div>
                            </Row>
                            <p style={{ textAlign: "center" }}>
                                Atlikušais laiks: <b style={{ color: "#CC3326" }}>00:00</b>
                            </p>
                            <h3>Jūsu kontroljautājuma atbildes laiks ir iztecējis.</h3>
                            <button
                                className="btn-submit"
                                onClick={actionHandler}
                            >
                                Sākt no sākuma
                            </button> */}
                        </Col>
                    </Row>
                    : <Row gutter={24} style={{ height: "100%" }}>
                        <Col span={24} lg={15} style={{ borderRight: mobile ? "" : "1px solid #E0E6EC", paddingTop: 48, paddingBottom: mobile ? 0 : 48 }}>
                            {
                                question?.public_image !== null && !zoomIn
                                    ? !!question?.video
                                        ? <div style={{ width: "100%", marginBottom: 32 }}>
                                            <video
                                                src={process.env.REACT_APP_API_URL + question?.public_image}
                                                controls={true}
                                                style={{ objectFit: "cover", width: "100%", cursor: "zoom-in" }}
                                                onClick={() => setZoomIn(true)}
                                            />
                                        </div>
                                        : <div className="img-wrap question-img" style={{ marginBottom: 22, height: "auto" }}>
                                            <img
                                                src={process.env.REACT_APP_API_URL + question?.public_image}
                                                alt=""
                                                onClick={() => setZoomIn(true)}
                                                style={{ cursor: "zoom-in", width: "100%", height: "auto" }}
                                            />
                                        </div>
                                    : <></>
                            }
                            <b className="question-big-text">{question?.[`question_${locale}`]}</b>
                            <Modal
                                visible={zoomIn}
                                footer={null}
                                onCancel={() => setZoomIn(false)}
                                closeIcon={<CloseOutlined />}
                                width="80%"
                            >
                                {
                                    question?.public_image !== null
                                        ? !!question?.video
                                            ? <div style={{ width: "100%", height: "100%" }}>
                                                <video

                                                    src={process.env.REACT_APP_API_URL + question?.public_image}
                                                    controls={true}
                                                    style={{ objectFit: "cover", width: "100%", cursor: "zoom-out" }}
                                                    onClick={() => setZoomIn(false)}
                                                />
                                            </div>
                                            : <div className="img-wrap question-img">
                                                <img
                                                    src={process.env.REACT_APP_API_URL + question?.public_image}
                                                    alt=""
                                                    onClick={() => setZoomIn(false)}
                                                    style={{ cursor: "zoom-out", width: "100%" }}
                                                />
                                            </div>
                                        : <></>
                                }
                            </Modal>
                            {/* {
                                question?.[`description_${locale}`] !== null
                                    ? <div className="description-wrap">
                                        <b>{question?.[`description_${locale}`]}</b>
                                    </div>
                                    : <></>
                            } */}
                        </Col>
                        <Col
                            span={24}
                            lg={9}
                            style={{
                                paddingTop: mobile ? 0 : 48,
                                paddingBottom: 48,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between"
                            }}
                        >
                            <div>
                                {/* <Row justify="space-between">
                                <p>Kontroljautajums</p>
                                <p>Atlikušais laiks:&nbsp;
                                    <CountDownTimer
                                        seconds={question.answer_time_limit}
                                        stopTimer={stopTimer}
                                        setTimeIsUp={setTimeIsUp}
                                    />
                                </p>
                            </Row> */}

                                <Radio.Group
                                    value={
                                        review
                                            ? question.answers.find((a: any) => !!a.selected)?.id
                                            : selectedAnswer
                                    }
                                    style={{ width: "100%" }}
                                    onChange={(e: any) => {
                                        if (review) return;
                                        //console.log(e.target.value)
                                        setAnswer(e.target.value)
                                    }}
                                >
                                    {
                                        question?.answers.map((answer: any, index: any) => {

                                            return <div
                                                key={index}
                                                className={`answer ${!!answer.correct && !!answer.selected
                                                    // ? selectedAnswer === undefined
                                                    //     ? "selected"
                                                    //     : "correct"
                                                    //         ? "correct"
                                                    //         : "incorrect"
                                                    ? "selected"
                                                    : ""
                                                    } ${review
                                                        ? !!answer.correct
                                                            ? "correct"
                                                            : "incorrect"
                                                        : ""
                                                    }`}
                                                onClick={() => {
                                                    if (review) return;
                                                    setAnswer(answer.id)
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <Radio value={answer.id} />
                                                <div className="answer-col">
                                                    {/* {
                                                        answer?.img !== null
                                                            ? <div className="img-wrap">
                                                                <img src={process.env.REACT_APP_API_URL + answer?.public_image} alt="" />
                                                            </div>
                                                            : <></>
                                                    } */}
                                                    <p>{answer?.[`answer_${locale}`]}</p>
                                                </div>
                                            </div>
                                        })
                                    }
                                </Radio.Group>
                                {/* {
                                correct === true
                                    ? <b className="warning green">Iesniegtā atbilde ir pareiza!</b>
                                    : correct === false
                                        ? <b className="warning red">Iesniegtā atbilde nav pareiza!</b>
                                        : <></>
                            } */}
                            </div>
                            {
                                review && csn
                                    ? <div>
                                        <Row gutter={8} style={{ marginBottom: 48 }}>
                                            <Col span={12}>
                                                <Button
                                                    className={`btn-submit ${exam.respQuestions.length === 0 || ind === 0 ? 'disabled' : ''}`}
                                                    type="primary"
                                                    disabled={exam.respQuestions.length === 0 || ind === 0}
                                                    style={{
                                                        width: "100%"
                                                    }}
                                                    onClick={() => goToIndex(ind - 1)}
                                                >
                                                    <ArrowLeft />
                                                </Button>
                                            </Col>
                                            <Col span={12}>
                                                <Button
                                                    className={`btn-submit ${ind === exam.respQuestions.length - 1 ? 'disabled' : ''}`}
                                                    type="primary"
                                                    disabled={ind === exam.respQuestions.length - 1}
                                                    style={{
                                                        width: "100%"
                                                    }}
                                                    onClick={() => goToIndex(ind + 1)}
                                                >
                                                    <ArrowRight />
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Button
                                            className={`btn-submit`}
                                            onClick={props.startOver}
                                            loading={props.loading}
                                        >
                                            {t('csn_tests.start_test_again')}
                                        </Button>
                                    </div>
                                    : <Button
                                        className={`btn-submit ${selectedAnswer ? '' : 'disabled'}`}
                                        onClick={actionHandler}
                                        loading={props.loading}
                                        disabled={!selectedAnswer}
                                    >
                                        {t('e_learning.next_question')}
                                        {/* {
                                    correct === true
                                        ? "Turpināt nodarbību"
                                        : correct === false
                                            ? "Sākt no sākuma"
                                            : "Iesniegt atbildi"
                                } */}
                                    </Button>
                            }

                        </Col>
                    </Row>
            }
        </div>
    } catch (err: any) {
        logReactError(err.message)
        return <div>ERROR</div>
    }

}