import React, { useEffect, useState } from "react";
import { TestLayout } from "./TestLayout";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { TheoryExamQuestion } from "../TheoryExams/TheoryExam/TheoryExamQuestion";
import { http } from "../../helpers/http";
import { TestEndCard } from "./TestEndCard";
import { message } from "antd";
import i18n from 'i18next';

type TestInstancePageProps = {
    isOpen: (state: boolean) => void;
    languages: any;
    student: any;
}

export const TestInstancePage = ({ ...props }: TestInstancePageProps) => {
    const history = useHistory();
    const { instance } = useParams<{ instance: string }>();
    const [exam, setExam] = useState(null as null | {
        questions: any[],
        ts: null | string,
        time_limit: number,
        ind: number,
        results: Number[],
        finished: boolean,
        respQuestions: any[]
    });
    const [endTimeData, setEndTimeData] = useState({
        timeEnded: false,
        timeRemaining: ""
    } as {
        timeEnded: boolean,
        timeRemaining: string
    })
    const [endExamData, setEndExamData] = useState(null as null | {
        success: boolean,
        successThreshold: number,
        score: number,
        total: number,
        timeLimit: number
    })
    const [loading, setLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [review, setReview] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    const retrieveExamFromStorage = () => {
        const instanceData = sessionStorage.getItem(instance);
        if (instanceData) {
            const parsedData = JSON.parse(instanceData);
            if (checkTime(parsedData.ts, parsedData.time_limit)) {
                return parsedData;
            }
        }
        history.goBack();
        return null;
    }

    const checkTime = (ts: string, timelimit: number) => {
        const legit = moment(ts).add(timelimit, 'minutes').isAfter(moment());
        if (!legit) {
            sessionStorage.removeItem(instance);
        }
        return legit;
    }

    const checkIfExamAvailable = () => {
        http.post('check-active-csdd-test', { hash: instance, category_id: props.student.category_id }).then((res) => {
            if (!!!res.data.data) {
                history.goBack();
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const submitAnswer = (result: number) => {
        if (exam === null) return;
        setLoading(true);
        const question = exam?.questions[exam?.ind]?.answers?.find((a: any) => a.id === result)?.e_module_exam_question_id;
        http.post(`save-csdd-test-answer/${question}`, { hash: instance, id: result }).then((res) => {
            // checkIfSysTimeChanged(res.data.data.ts);
            setLoading(false);
            const data = {
                questions: exam?.questions,
                ts: exam?.ts,
                time_limit: exam.time_limit,
                ind: exam?.ind,
                results: [...exam?.results, result],
                finished: false,
                respQuestions: exam?.respQuestions
            }
            if (exam?.ind < exam?.questions.length - 1) {
                data.ind = exam?.ind + 1;
            } else {
                data.finished = true;
                endExam(data.results);
            }
            setExam(data);
        }).catch((error) => {
            message.error("Kļūda saglabājot atbildi");
            setLoading(false);
        });
    }

    const saveInstance = (data: any) => {
        sessionStorage.setItem(instance, JSON.stringify(data));
    }

    const removeInstance = () => {
        sessionStorage.removeItem(instance);
    }

    const endExam = (results: Number[] = exam?.results ?? [], timeEnded?: boolean) => {
        http.post('end-csdd-test', { hash: instance, results: JSON.stringify(results), time_ended: timeEnded ? 1 : undefined }).then((res) => {
            if (res?.data?.data?.exam) {
                const timeLimit = exam?.time_limit ?? 30;
                const { questions, exam_max_errors } = res.data.data;
                const examResp = res.data.data.exam;
                const questionsWithInd = questions.map((q: any, i: number) => { return { ...q, ind: i } });
                const markedFailedQuestions = questionsWithInd.filter((q: any) => !!q.has_mistake)
                setExam((prev: any) => ({ ...prev, questions: questionsWithInd, ind: 0, finished: true, respQuestions: markedFailedQuestions }));
                setEndExamData({
                    success: !!examResp?.passed,
                    successThreshold: (exam?.questions?.length ?? questions?.length) - exam_max_errors,
                    score: (exam?.questions?.length ?? questions?.length) - examResp?.mistakes,
                    total: exam?.questions?.length ?? questions?.length,
                    timeLimit
                });
            }

        }).catch((error) => {
            console.log(error);
        });
        removeInstance();
    }

    const goToInd = (ind: number) => {
        setExam((prev: any) => ({ ...prev, ind }));
    }

    const goToNewTest = () => {
        removeInstance();
        history.goBack();
    }

    const checkIfSysTimeChanged = (dbTime: string) => {
        const dbTimeMoment = moment(dbTime);
        const sysTime = moment();
        const diff = sysTime.diff(dbTimeMoment, 'seconds');
        return diff > 20;
    }

    useEffect(() => {
        setExam(retrieveExamFromStorage());
        checkIfExamAvailable();
    }, []);

    useEffect(() => {
        if (exam && !exam?.finished) {
            saveInstance(exam);
        }
    }, [exam]);

    useEffect(() => {
        if (endTimeData?.timeEnded) {
            endExam([], true);
        }
    }, [endTimeData]);

    useEffect(() => {
        setIsMobile(window.matchMedia("(max-width: 560px)").matches)
    });

    return <TestLayout
        isOpen={props.isOpen}
        languages={props.languages}
        timerProps={{
            seconds: exam?.time_limit! * 60,
            stopTimer: exam?.finished || false,
            setTimeIsUp: (timeEnded) => setEndTimeData((prev: any) => ({ ...prev, timeEnded })),
            returnTime: (timeRemaining) => setEndTimeData((prev: any) => ({ ...prev, timeRemaining })),
            ts: exam?.ts || moment().format("YYYY-MM-DD HH:mm:ss")
        }}
        exam={exam}
        getLanguage={setSelectedLanguage}
    >
        {
            exam?.finished && !review
                ? <TestEndCard
                    data={{
                        ...endExamData,
                        ...endTimeData
                    } ?? undefined}
                    isMobile={isMobile}
                    setReview={setReview}
                />
                : <div className="module-exam-question-card">
                    <TheoryExamQuestion
                        question={review ? exam?.respQuestions[exam?.ind] : exam?.questions[exam?.ind]}
                        nextQuestion={review ? goToInd : submitAnswer}
                        review={review}
                        ind={exam?.ind}
                        mobile={isMobile}
                        loading={loading}
                        exam={exam}
                        csn={true}
                        startOver={goToNewTest}
                        language={selectedLanguage}
                    />
                </div>
        }

    </TestLayout>
}