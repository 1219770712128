import { Button, Col, Modal, Row, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";

type MandatoryDataModalProps = {
    student: any;
}

export const MandatoryDataModal = ({ student }: MandatoryDataModalProps) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [cities, setCities] = useState([] as any);
    const [data, setData] = useState({
        drivingCity: undefined as number | undefined,
        theoryCity: undefined as number | undefined,
        transmission: undefined as string | undefined
    })
    const [needTransmission, setNeedTransmission] = useState(true);
    const nonDrivingCategory = student.category?.value?.toLowerCase().includes("95") || student.category?.value?.toLowerCase().includes("pmp");

    const fetchCities = () => {
        http.get('cities').then((res: AxiosResponse) => {
            setCities(res.data.data)
        })
    }

    const saveSettings = () => {
        let dataToSend = {
            driving_city: data.drivingCity,
            theory_city: data.theoryCity,
            transmission: data.transmission
        }
        if (!needTransmission) delete dataToSend.transmission
        http.post(`/student-driving-theory-preferences/${student.category_id}`, { ...dataToSend }).then(() => {
            setShowModal(false);
        }).catch(() => {
            message.error("Kļūda!")
            setShowModal(false);
        })
    }

    useEffect(() => {
        fetchCities();
    }, []);

    useEffect(() => {
        const category = student.categories.find((category: any) => {
            return category.category_id === student.category_id
        })
        setShowModal(() => {
            return (!!!category.driving_city && !nonDrivingCategory)
                || !!!category.theory_city
                || (!!!category.transmission && !!student.category.ask_transmission)
        });
        setData({
            drivingCity: category.driving_city ?? undefined,
            theoryCity: category.theory_city ?? undefined,
            transmission: category.transmission ?? undefined
        })
        setNeedTransmission(!!student.category.ask_transmission)
    }, [student])

    return <Modal
        visible={showModal}
        // onCancel={() => setShowModal(false)}
        footer={<Row justify="end">
            <Button
                type="primary"
                disabled={
                    (data.drivingCity === undefined && !nonDrivingCategory)
                    || data.theoryCity === undefined
                    || (data.transmission === undefined && needTransmission)
                }
                onClick={saveSettings}
            >
                Apstiprināt
            </Button>
        </Row>}
    >
        <h2>{t('students.mandatory_learning_info')}</h2>
        <p><b>{t('students.fill_missing_information')}</b></p>
        <Row style={{ width: "100%" }}>
            <Col span={24}>
                {
                    nonDrivingCategory
                        ? <></>
                        : <>
                            <p>{t('students.desired_driving_city')}:</p>
                            <Select
                                style={{ width: "100%", marginBottom: 10 }}
                                value={data.drivingCity}
                                onChange={e => {
                                    setData((prev: any) => {
                                        return {
                                            ...prev,
                                            drivingCity: e
                                        }
                                    })
                                }}
                            >
                                {
                                    cities.map((city: any) => {
                                        return <Select.Option key={city.id} value={city.id}>
                                            {city.value}
                                        </Select.Option>
                                    })
                                }
                            </Select>
                        </>
                }
                <p>{t('students.desired_theory_city')}:</p>
                <Select
                    style={{ width: "100%", marginBottom: 10 }}
                    value={data.theoryCity}
                    onChange={e => {
                        setData((prev: any) => {
                            return {
                                ...prev,
                                theoryCity: e
                            }
                        })
                    }}
                >
                    {
                        cities.map((city: any) => {
                            return <Select.Option key={city.id} value={city.id}>
                                {city.value}
                            </Select.Option>
                        })
                    }
                </Select>
                {
                    needTransmission
                    && <>
                        <p>{t('common.transmission.transmission')}:</p>
                        <Select
                            style={{ width: "100%", marginBottom: 10 }}
                            value={data.transmission}
                            onChange={e => {
                                setData((prev: any) => {
                                    return {
                                        ...prev,
                                        transmission: e
                                    }
                                })
                            }}
                        >
                            <Select.Option value={"manual"}>
                                {t('common.transmission.manual')}
                            </Select.Option>
                            <Select.Option value={"auto"}>
                                {t('common.transmission.auto')}
                            </Select.Option>
                        </Select>
                    </>
                }
            </Col>
        </Row>
    </Modal>
}