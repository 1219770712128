import { Card, Button, message, Spin } from "antd"
import { t } from "i18next"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { http } from "../../helpers/http"
import { AxiosError, AxiosResponse } from "axios"
import { BuySubModal } from "./BuySubModal"
import moment from "moment"

type StartTestCardProps = {
    student: any;
    returnTime: (time: number) => void;
    returnExam: (exam: any) => void;
    returnIsDemo?: (isDemo: boolean) => void;
    returnIsPremium?: (premium: boolean) => void;
    refreshBalance: () => void;
}

type HistoryState = {
    type?: string;
}

export const StartTestCard = ({ ...props }: StartTestCardProps) => {

    const history = useHistory<HistoryState>();
    const [loadingSettings, setLoadingSettings] = useState(false);
    const [loadingSubStatus, setLoadingSubStatus] = useState(false);
    const [loading, setLoading] = useState(false);
    const [demo, setDemo] = useState(true);
    const [hasPremium, setHasPremium] = useState(false);
    const [settings, setSettings] = useState<any>();
    const [demoCounter, setDemoCounter] = useState({
        remaining: 0,
        allowed: 0
    });
    const [showBuySubModal, setShowBuySubModal] = useState(false);
    const [subscribtionData, setSubscribtionData] = useState<any>(null);
    const [isErrorCorrection, setIsErrorCorrection] = useState(false);

    const getSettings = () => {
        setLoadingSettings(true);
        http.get('csdd-test-settings', { params: { category: props.student.category_id } }).then((res: AxiosResponse) => {
            setSettings(res.data);
            setLoadingSettings(false);
        }).catch((error: AxiosError) => {
            console.log(error);
            message.error(t('errors.error'));
            setLoadingSettings(false);
        })
    }

    const killDeadSession = (session: string) => {
        sessionStorage.removeItem(session);
    }

    const getDeadSessions = () => {
        http.get('csdd-test-unfinished').then((res: AxiosResponse) => {
            if (res.data) {
                res.data.forEach((session: { hash: string }) => {
                    killDeadSession(session.hash);
                });
            }
        }).catch((error: AxiosError) => {
            console.log(error);
        })
    }

    const checkIfSubscribed = () => {
        http.get(`csdd-test-subscription`).then((res: AxiosResponse) => {
            console.log(res)
            setSubscribtionData(res.data.data);
        }).catch((error: AxiosError) => {
            console.log(error);
            message.error(t('errors.error'));
        })
    }

    const getDemoCounter = () => {
        setLoadingSubStatus(true);
        http.get(`csdd-test-demo-counter`, { params: { category: props.student.category_id } }).then((res: AxiosResponse) => {
            if (!!res.data.sub) {
                setHasPremium(true);
                setDemo(false);
                setLoadingSubStatus(false);
                return
            }
            if (res.data.remaining === 0) {
                setDemo(false);
            }
            setDemoCounter(res.data);
            setLoadingSubStatus(false);
        }).catch((error: AxiosError) => {
            console.log(error);
            message.error(t('errors.error'));
            setLoadingSubStatus(false);
        })
    }

    const handleStartTest = () => {
        setLoading(true);
        http.post('make-csdd-test', {
            demo: hasPremium ? 0 : 1,
            category: props.student?.category_id,
            error_correction: isErrorCorrection ? 1 : undefined
        }).then((res: AxiosResponse) => {
            const { exam, questions, hash, time_limit } = res.data.data;
            setLoading(false);
            if (exam && questions.length > 0) {
                const data = {
                    questions: questions,
                    ts: exam.created_at,
                    time_limit,
                    ind: 0,
                    results: []
                }
                sessionStorage.setItem(hash, JSON.stringify(data));
                history.push(`/tests/start-test/${hash}`);
            } else {
                message.error(t('errors.error'));
                console.log("Error");
            }
        }).catch((error: AxiosError) => {
            console.log(error);
            message.error(t('errors.error'));
            setLoading(false);
        });
    }

    useEffect(() => {
        getDeadSessions();
        getSettings();
        getDemoCounter();
        checkIfSubscribed();
    }, [])

    useEffect(() => {
        if (settings) {
            if (demo) {
                props.returnTime(Number(settings.demo_test_timer));
                props.returnExam({
                    total: settings.demo_test_questions,
                    ind: -1,
                });
            } else {
                props.returnTime(Number(settings.test_timer));
                props.returnExam({
                    total: settings.test_questions,
                    ind: -1,
                });
            }
        }
    }, [settings, demo])

    useEffect(() => {
        if (props.returnIsPremium) {
            props.returnIsPremium(hasPremium)
        }
        if (hasPremium && showBuySubModal) {
            handleStartTest();
        }
    }, [hasPremium])

    useEffect(() => {
        if (props.returnIsDemo) {
            props.returnIsDemo(demo)
        }
    }, [demo])

    useEffect(() => {
        if (history.location.state) {
            setIsErrorCorrection(history.location.state.type === "error_correction");
        }
    }, [history])

    return <Card
        className="shadow-card"
        style={{
            maxWidth: 680,
        }}
        bodyStyle={{
            padding: 48
        }}
    >
        <h2 className="heading2">{t('csn_tests.csn_control_test')}</h2>
        <p className="paragraph" style={{ marginBottom: 20 }}>{t('csn_tests.what_is_csn_test_info')}</p>
        <div
            style={{
                paddingLeft: 20,
                borderLeft: "2px solid #059DC1",
                marginBottom: 20
            }}
        >
            <h3 className="heading3">
                {t('csn_tests.pay_attention')}
            </h3>
            <p className="paragraph" style={{ margin: 0 }}>{t('csn_tests.pay_attention_info')}</p>
        </div>
        {
            hasPremium
                ? <p className="bold-paragraph" style={{ marginBottom: 20, color: "#079DC1" }}>
                    {t('csn_tests.subscription_till')} <span style={{ fontWeight: 700 }}>{subscribtionData?.expire_day ? moment(subscribtionData?.expire_day).format("DD.MM.YYYY") : ""}</span>
                </p>
                : <div
                    style={{
                        borderRadius: "4px",
                        background: "#F0F2F5",
                        padding: "16px 20px",
                        marginBottom: 20
                    }}
                >
                    <p className="paragraph" style={{ margin: 0 }}>{t('csn_tests.your_allowed_tries')}</p>
                    {
                        loadingSubStatus
                            ? <Spin />
                            : <p className="bold-paragraph" style={{ margin: 0, fontWeight: 500 }}><span style={{ color: "#CF1726" }}>{demoCounter?.remaining}</span> / {demoCounter?.allowed}</p>
                    }
                </div>
        }
        <Button
            type="primary"
            className={`btn-start-csn-test`}
            onClick={() => !demo && !hasPremium ? setShowBuySubModal(true) : handleStartTest()}
            disabled={loading || loadingSettings || loadingSubStatus}
            loading={loading || loadingSettings || loadingSubStatus}
        >
            {t('e_learning.start_test')}
        </Button>
        <BuySubModal
            visible={showBuySubModal}
            setVisible={setShowBuySubModal}
            price={settings?.subscription_price}
            discountedPrice={settings?.subscription_price_discounted}
            checkIfSubscribed={() => { getDemoCounter(); checkIfSubscribed() }}
            refreshBalance={props.refreshBalance}
        />
    </Card>
}