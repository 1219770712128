import React, { useEffect, useState } from "react";
import { StartTestCard } from "./StartTestCard";
import { TestLayout } from "./TestLayout";
import moment from "moment";

type StartTestPageProps = {
    isOpen: (state: boolean) => void;
    languages: any;
    student: any;
    refreshBalance: () => void;
}

export const StartTestPage = ({ ...props }: StartTestPageProps) => {
    const [previewTime, setPreviewTime] = useState(30);
    const [exam, setExam] = useState(null as null | {
        questions: any[],
        ind: number,
    });
    const [isDemo, setIsDemo] = useState(true);
    const [isPremium, setIsPremium] = useState(false);

    useEffect(() => {
        console.log("isDemo", isDemo, "isPremium", isPremium);
    }, [isDemo, isPremium]);

    return <TestLayout
        isOpen={props.isOpen}
        languages={props.languages}
        previewTime={previewTime}
        exam={exam}
        getLanguage={(lang: string) => { }}
        goBackUrl={(isDemo || (!isDemo && !isPremium)) ? "/planner" : "/tests"}
    >
        <StartTestCard
            student={props.student}
            returnTime={(time: number) => setPreviewTime(time)}
            returnExam={(exam: any) => setExam(exam)}
            returnIsDemo={(isDemo: boolean) => setIsDemo(isDemo)}
            returnIsPremium={(premium: boolean) => setIsPremium(premium)}
            refreshBalance={props.refreshBalance}
        />
    </TestLayout>
}