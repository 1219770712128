import { Modal, Slider } from "antd";
import { BackwardOutlined, } from "@ant-design/icons";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as IconBookmarkPin } from "../../../img/ic-cc-1.svg";
import { ReactComponent as IconLargeCheck } from "../../../img/ic-check-large.svg";
import { ReactComponent as ArrowRight } from "../../../img/ic-right-arrow-circle.svg";
import { AddBookmark } from "../AddBookmark";
import { VideoQuestion } from "../VideoQuestion";
import { ReactComponent as BigPlayBtn } from "../../../img/ic-play-large.svg";
import { useTranslation } from "react-i18next";
import { http } from "../../../helpers/http";
import { ConnectionCheck } from "../../../helpers/ConnectionCheck";
declare global {
    interface Document {
        exitFullscreen: () => Promise<void>;
        mozCancelFullScreen: any;
        webkitExitFullscreen: any;
        fullscreenElement: any;
        mozFullScreenElement: any;
        webkitFullscreenElement: any;
    }
}

export const LessonPlayer = (props: any) => {
    const { t } = useTranslation();
    const {
        bookmarksList,
        url,
        videoID,
        jumpTo,
        question,
        setProgress,
        done,
        goToNextChapter,
        resetTime,
        lesson,
        changeQuality,
        qualities,
        stopVideo,
        videoTime
    } = props;

    const player = useRef(null as any);
    const bar = useRef(null as any);
    const progressBar = useRef(null as any);
    const pointerTip = useRef(null as any);
    const videoContainer = useRef(null as any);
    const controlBar = useRef(null as any);

    const [playing, setPlaying] = useState(false);
    const [changeVolume, setChangeVolume] = useState({
        sliderOpen: false,
        volume: 0.75,
        mute: false
    } as any);
    const [timer, setTimer] = useState({
        currentTime: "00:00:00",
        duration: "00:00:00"
    } as any);
    const [canPlay, setCanPlay] = useState(false);
    const [pointerProps, setPointerProps] = useState({
        time: "00:00:00",
        showTip: false
    });
    const [showQuarterSign, setShowQuarterSign] = useState(false);
    const [controls, setControls] = useState({
        disabled: {
            rewind: false,
            bookmark: false,
            settings: false,
            fullscreen: navigator.userAgent.includes("iPhone"),
            all: false,
        }
    });
    const [bookmarks, setBookmarks] = useState(bookmarksList);
    const [bookmarkForm, setBookmarkForm] = useState({
        id: undefined as any,
        time: "00:00:00" as String,
        show: false,
        comment: "",
        video_id: null as any
    });
    const [fullscreen, setFullscreen] = useState(false);
    const [showControlBar, setShowControlBar] = useState(false);
    const [questionPopTime, setQuestionPopTime] = useState(null as any);
    const [popQuestion, setPopQuestion] = useState(false);
    const [loadedPlayer, setLoadedPlayer] = useState(false);
    const [showDoneOverlay, setShowDoneOverlay] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [selectedQuality, setSelectedQuality] = useState("medium");
    const [lastProgress, setLastProgress] = useState({
        time: 0,
        playing: false
    });
    const [playerSize, setPlayerSize] = useState({
        width: 0,
        height: 0
    })
    const [haveError, setHaveError] = useState({
        state: false,
        time: 0
    })
    const [videoEnded, setVideoEnded] = useState({
        state: false,
        time: [0, 0]
    });
    let mouse = {
        down: false,
        up: false,
        move: false,
    };

    const playHandler = (e: Event) => {
        e.stopPropagation();
        playToggle();
    };

    const playToggle = (override: Boolean = false) => {
        if (controls.disabled.all && !override) return;
        srcErrorHandler();
        setPlaying(!playing);
        !playing
            ? player.current.play()
            : player.current.pause()
    };

    const srcErrorHandler = () => {
        if (haveError.state) {
            player.current.load();
            player.current.currentTime = haveError.time
        }
    }

    const volumeHandler = (action: string, volume: any = changeVolume.volume) => {
        if (action === "open") {
            setChangeVolume((prev: any) => {
                return {
                    ...prev,
                    sliderOpen: true
                }
            });
        } else if (action === "change") {
            setChangeVolume((prev: any) => {
                return {
                    ...prev,
                    volume: volume,
                    mute: false
                }
            });
        } else if (action === "close") {
            setChangeVolume((prev: any) => {
                return {
                    ...prev,
                    sliderOpen: false
                }
            });
        } else if (action === "mute") {
            setChangeVolume((prev: any) => {
                return {
                    ...prev,
                    mute: !prev.mute
                }
            });
        }
    };

    const rewindQuarterHandler = (e: Event) => {
        e.stopPropagation();
        if (controls.disabled.rewind) return;
        setShowQuarterSign(true);
        player.current.currentTime -= 15;
        progressBar.current.style.width = (player.current.currentTime / player.current.duration) * 100 + "%";
    };

    const bookmarkHandler = (e: Event) => {
        e.stopPropagation();
        if (controls.disabled.bookmark) return;
        player.current.pause();
        setBookmarkForm({
            id: undefined,
            time: player.current.currentTime,
            show: true,
            comment: "",
            video_id: videoID
        });
        setBookmarks((prev: any) => {
            return [
                ...prev,
                {
                    bookmark: player.current.currentTime,
                    posX: getVideoBarPoint(player.current.currentTime)
                }
            ]
        })
    };

    const editBookmark = (index: any) => {
        const bookmark = bookmarks[index];
        //console.log(bookmark)
        if (bar.current.allowEvent) {
            player.current.currentTime = bookmark.bookmark;
            player.current.pause();
        }
        setBookmarkForm({
            id: bookmark.id,
            time: bookmark.bookmark,
            show: true,
            comment: bookmark.comment,
            video_id: videoID
        });
    };

    const cancelAddBookmark = () => {
        if (bookmarks[bookmarks.length - 1].id) return;
        setBookmarks((prev: any) => {
            return prev.slice(0, -1);
        })
    }

    const settingsHandler = (e: Event, action: String) => {
        e.stopPropagation();
        if (controls.disabled.settings) return;
        setSettingsOpen(action === "open");
    };

    const setVideoQuality = (quality: any) => {
        setSelectedQuality(quality);
        changeQuality(quality);
        setCanPlay(false);
        setLastProgress((prev: any) => {
            return {
                ...prev,
                playing
            }
        })
    }

    const fullscreenHandler = (e: any) => {
        e.stopPropagation();
        if (controls.disabled.fullscreen) return;
        fullScreenToggle();
    };

    const fullScreenToggle = () => {
        setFullscreen(!fullscreen);
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else if (document.webkitFullscreenElement) {
            // Need this to support Safari
            document.webkitExitFullscreen();
        } else if (videoContainer.current.webkitRequestFullscreen) {
            // Need this to support Safari
            videoContainer.current.webkitRequestFullscreen();
        } else {
            videoContainer.current.requestFullscreen();
        }
    }

    const getVideoBarPoint = (seconds: any) => {
        const barPercentage = (seconds / player.current.duration) * 100 + "%"
        return barPercentage
    };

    const trimHours = (time: String) => {
        const splittedTime = time.split(":");
        if (!(splittedTime[0] === "00")) return;
        return splittedTime[1] + ":" + splittedTime[2];
    }

    const questionHandler = () => {
        player.current.pause();
        setPopQuestion(true);
    }

    const answerHandler = (correct: Boolean) => {
        if (correct) {
            setQuestionPopTime(null);
            setPopQuestion(false);
            setPlaying(true);
            playToggle(true);
        } else {
            setQuestionPopTime((randomQuestionTime()))
            setPopQuestion(false);
            player.current.currentTime = 0;
        }
    }

    const randomQuestionTime = () => {
        //console.log("DURATION: ", player.current.duration)
        const middleVideo = player.current.duration / 2;
        const offset = middleVideo * .2;
        const max = Number((middleVideo + offset).toFixed(0));
        const min = Number((middleVideo - offset).toFixed(0));
        //console.log(Math.floor((Math.random() * (max - min) + min)))
        return Math.floor((Math.random() * (max - min) + min))
    }

    const mouseDownHandler = (e: any) => {
        //console.log(e.target.time)
        const currentPointTime = (e.offsetX / bar.current.getBoundingClientRect().width) * player.current.duration
        player.current.currentTime = currentPointTime;
        progressBar.current.style.width = e.offsetX + "px"
        bar.current.style.height = "100px"
        bar.current.style.top = "-15px"
        mouse = {
            ...mouse,
            down: true,
            up: false,
        };
    }

    useEffect(() => {
        player.current.allowPlay = true;
        bar.current.allowEvent = true;
        controlBar.current.allowEvent = true;
        if (!lesson) {
            setControls({
                disabled: {
                    rewind: false,
                    bookmark: true,
                    settings: false,
                    fullscreen: navigator.userAgent.includes("iPhone"),
                    all: false,
                }
            })
        }
        controlBar.current.addEventListener("touchstart", () => {
            player.current.isMobile = true;
        })
        controlBar.current.addEventListener("mouseover", () => {
            setTimeout(() => setShowControlBar(true), 30);
        });
        controlBar.current.addEventListener("mouseleave", () => {
            console.log("leave")
            setTimeout(() => setShowControlBar(false), 30);
        });
        player.current.addEventListener("canplay", (e: any) => {
            setCanPlay(true);
            // setPlayerSize({
            //     width: player.current.getBoundingClientRect().width,
            //     height: player.current.getBoundingClientRect().height,
            // })
            setTimer((prev: any) => {
                return {
                    ...prev,
                    duration: moment.utc(player.current.duration * 1000).format("HH:mm:ss")
                }
            })
        });
        player.current.addEventListener("loadedmetadata", () => {
            setCanPlay(true);
            setTimer((prev: any) => {
                return {
                    ...prev,
                    duration: moment.utc(player.current.duration * 1000).format("HH:mm:ss")
                }
            })
        })
        player.current.addEventListener("error", (event: any) => {
            if (event?.target?.error?.message?.includes('Failed to open media')) return;
            if (event?.target?.error?.message?.includes('Format error')) return;
            if (event?.target?.error?.message) {
                if (event?.target?.error?.message?.length > 0) {
                    setHaveError({
                        state: true,
                        time: player.current.currentTime
                    })
                    Modal.confirm({
                        title: "Kļūda!",
                        content: <div>
                            <p>
                                Ir notikusi kļūda, lūgums pārbaudīt savu interneta savienojumu!
                            </p>
                            <p><b>Kļūda: {event?.target?.error?.message}</b></p>,
                        </div>,
                        onOk: () => {

                        }
                    })
                }
            }

        })
        player.current.addEventListener("play", (e: any) => {
            if (lesson) {
                if (player.current.currentTime >= player.current.duration) {
                    setVideoEnded({
                        state: true,
                        time: [player.current.currentTime, player.current.duration]
                    });
                } else {
                    setProgress(player.current.currentTime, player.current.duration);
                }
            }
            setPlayerSize({
                width: player.current.getBoundingClientRect().width,
                height: player.current.getBoundingClientRect().height,
            })
            //console.log(player.current.allowPlay)
            if (!player.current.allowPlay) {
                player.current.pause();
                return;
            }
            if (lesson) {
                setBookmarks((prev: any) => {
                    if (prev.length === 0) return [...prev];
                    if (prev[prev.length - 1].id) {
                        return [...prev]
                    } else {
                        return prev.slice(0, -1);
                    }
                })
                setBookmarkForm((prev: any) => {
                    return {
                        ...prev,
                        show: false
                    }
                });
            }
        });
        player.current.addEventListener("pause", () => {
            setPlaying(false);
        });
        player.current.addEventListener("mousedown", () => {
            return;
        });
        player.current.addEventListener("ended", () => {
            if (lesson) {
                setVideoEnded({
                    state: true,
                    time: [player.current.currentTime, player.current.duration]
                });
            }

            // url = player.current.src
            // player.current.src = url;
            setPlaying(false);
        });
        player.current.addEventListener("timeupdate", () => {
            if (lesson) {
                if (player.current.currentTime >= player.current.duration)
                    setVideoEnded({
                        state: true,
                        time: [player.current.currentTime, player.current.duration]
                    });
            }

            if (!progressBar.current) return;
            progressBar.current.style.width = (player.current.currentTime / player.current.duration) * 100 + "%";
            setTimer((prev: any) => {
                return {
                    ...prev,
                    currentTime: moment.utc(player.current.currentTime * 1000).format("HH:mm:ss")
                }
            })
        });
        bar.current.addEventListener('mousedown', (e: any) => {
            if (!bar.current.allowEvent) return;
            mouseDownHandler(e);
        });
        bar.current.addEventListener('mouseup', (e: any) => {
            //console.log(bar.current.allowEvent)
            if (!bar.current.allowEvent) return;
            bar.current.style.height = "10px"
            bar.current.style.top = "-3px"
            mouse = {
                ...mouse,
                down: false,
                up: true,
            }
        });
        bar.current.addEventListener('mousemove', (e: any) => {
            const currentPointTime = (e.offsetX / bar.current.getBoundingClientRect().width) * player.current.duration * 1000
            pointerTip.current.style.left = e.offsetX - 23 + "px"
            if (mouse.down) {
                mouseDownHandler(e);
            }
            setPointerProps((prev: any) => {
                return {
                    ...prev,
                    time: moment.utc(currentPointTime).format("HH:mm:ss")
                }
            });
        });
        bar.current.addEventListener('mouseover', (e: any) => {
            setPointerProps((prev: any) => {
                return {
                    ...prev,
                    showTip: true
                }
            });
        });
        bar.current.addEventListener('mouseleave', (e: any) => {
            console.log("hide pointer")
            setPointerProps((prev: any) => {
                return {
                    ...prev,
                    showTip: false
                }
            });
        });
        player.current.addEventListener("loadeddata", () => {
            setLoadedPlayer(true);
            if (!lesson) return;
            if (jumpTo !== undefined && bar.current.allowEvent) {
                player.current.currentTime = jumpTo;
            }
            return props.loaded(true);
        });
    }, []);

    useEffect(() => {
        setBookmarks(bookmarksList)
    }, [bookmarksList]);

    useEffect(() => {
        if (question) {
            setQuestionPopTime(done ? null : randomQuestionTime());
        }
    }, [loadedPlayer])

    useEffect(() => {
        if (haveError.state) {
            const errorTime = Number(haveError.time.toFixed(0))
            const currentTimer = Number(moment(timer.currentTime, "HH:mm:ss").format("s"))
            if ((currentTimer - errorTime) > 1) {
                setHaveError({
                    state: false,
                    time: currentTimer
                })
            }
        }
        if (questionPopTime === Number(player.current.currentTime.toFixed(0))) {
            questionHandler();
        }
    }, [timer])

    useEffect(() => {
        if (showQuarterSign) {
            setTimeout(() => setShowQuarterSign(false), 100)
        }
    }, [showQuarterSign])

    useEffect(() => {
        player.current.volume = changeVolume.volume
        if (changeVolume.mute) {
            player.current.volume = 0
        }
    }, [changeVolume])

    useEffect(() => {
        if (!lesson) return;
        bar.current.allowEvent = done;
        controlBar.current.allowEvent = done;
        //console.log(done, player.current.currentTime)
        if (done && player.current.currentTime > 0) {
            setShowDoneOverlay(true);
            if (fullscreen) {
                fullScreenToggle();
            }
        }
    }, [done])

    useEffect(() => {
        if (!lesson) return;
        if (bookmarkForm.show) player.current.pause();
        setControls((prev: any) => {
            return {
                ...prev,
                disabled: {
                    ...prev.disabled,
                    bookmark: lesson ? bookmarkForm.show : true
                }
            }
        })
    }, [bookmarkForm]);

    useEffect(() => {
        if (!lesson) return;
        player.current.allowPlay = !popQuestion;
        bar.current.allowEvent = !popQuestion && done;
        controlBar.current.allowEvent = !popQuestion && done;
        setControls((prev: any) => {
            return {
                ...prev,
                disabled: {
                    ...prev.disabled,
                    all: popQuestion
                }
            }
        });
    }, [popQuestion]);

    useEffect(() => {
        // console.log(controls, done)
        // player.current.allowPlay = !controls.disabled.all;
        // bar.current.allowEvent = !controls.disabled.all;
        // controlBar.current.allowEvent = !controls.disabled.all;
    }, [controls]);

    useEffect(() => {
        //console.log("IS MOBILE: ", player?.current?.isMobile)
    }, [player?.current?.isMobile]);

    useEffect(() => {
        if (jumpTo !== undefined && bar.current.allowEvent) {
            player.current.currentTime = jumpTo;
            setShowDoneOverlay(false);
        }
    }, [jumpTo]);

    useEffect(() => {
        if (!playing) {
            setShowControlBar(true);
        } else {
            setTimeout(() => setShowControlBar(false), 2000)
        }
    }, [playing])

    useEffect(() => {
        if (!canPlay) {
            const time = moment(timer.currentTime, "HH:mm:ss");
            const millis = 1000 * (time.seconds() + 60 * (time.minutes() + 60 * time.hours()));
            setLastProgress((prev: any) => {
                return {
                    ...prev,
                    time: millis
                }
            });
        } else {
            //console.log(lastProgress)
            player.current.currentTime = lastProgress.time / 1000;
            if (lastProgress.playing) player.current.play();
            setTimer((prev: any) => {
                return {
                    ...prev,
                    currentTime: moment.utc(lastProgress.time).format("HH:mm:ss")
                }
            })
        }
    }, [canPlay]);

    useEffect(() => {
        if (stopVideo) {
            player.current.pause();
            videoTime(timer.currentTime)
        }
    }, [stopVideo])

    useEffect(() => {
        if (done) return;
        if (videoEnded.state) {
            if (questionPopTime !== null) {
                setQuestionPopTime((randomQuestionTime()))
                setPopQuestion(false);
                player.current.currentTime = 0;
                setVideoEnded({
                    state: false,
                    time: [0, 0]
                });
            } else {
                setProgress(videoEnded.time[0], videoEnded.time[1])
            }
        }
    }, [videoEnded])

    return <>
        {/* <b>jautājuma laiks: {moment.utc(questionPopTime * 1000).format("HH:mm:ss")}</b> */}
        {
            !canPlay
                ? <div style={{
                    width: playerSize.width,
                    height: playerSize.height,
                    backgroundColor: "black",
                }}></div>
                : <></>
        }
        <div ref={videoContainer} className={`lesson-player ${canPlay
            ? ""
            : "loading"} ${fullscreen
                ? "fullscreen"
                : ""}
                    `}
        >
            <ConnectionCheck></ConnectionCheck>
            {
                showDoneOverlay
                    ? <div className="lesson-done-overlay">
                        <div className="content">
                            <IconLargeCheck className="check-icon" />
                            <p>{t('e_learning.you_are_completed_lesson')}.</p>
                            <div className="link-next-chapter" onClick={goToNextChapter}>
                                <span>{t('e_learning.next_lesson')}</span>
                                <ArrowRight />
                            </div>
                        </div>
                    </div>
                    : <></>
            }
            {
                question
                    ? popQuestion
                        ? <VideoQuestion
                            question={question}
                            setAnswerIsCorrect={answerHandler}
                            fullScreen={fullscreen}
                        />
                        : <></>
                    : <></>
            }
            {
                bookmarkForm.show
                    ? <AddBookmark
                        id={bookmarkForm.id}
                        time={bookmarkForm.time}
                        videoID={bookmarkForm.video_id}
                        comment={bookmarkForm.comment}
                        refresh={props.refreshBookmarks}
                        setShow={(show: Boolean) => setBookmarkForm((prev: any) => {
                            return {
                                ...prev,
                                show: show
                            }
                        })}
                        cancelAddBookmark={cancelAddBookmark}
                    />
                    : <></>
            }
            <div className={`minus-quarter-sign ${showQuarterSign ? "show" : ""}`}><BackwardOutlined />15</div>
            <video
                ref={player}
                src={url}
                playsInline
                disablePictureInPicture={true}
            />
            {
                playing
                    ? <></>
                    : <div className="btn-play-big" onClick={(e: any) => playHandler(e)}>
                        <BigPlayBtn />
                    </div>
            }
            <div
                className="player-controls"
                onClick={(e: any) => {
                    if (playing && !player.current.isMobile) playHandler(e)
                    else if (playing && player.current.isMobile && !showControlBar) playHandler(e)
                }}
            >
                <div
                    ref={controlBar}
                    className={`controls-bar ${fullscreen
                        ? "fullscreen"
                        : ""
                        } ${showControlBar
                            ? "show"
                            : "hide"
                        }`}
                >
                    <button
                        onClick={(e: any) => showControlBar && playHandler(e)}
                        className={`player-btn btn-play-small ${playing ? "pause" : ""}`}
                        title={t('e_learning.play')}
                    >
                    </button>
                    <button title={t('e_learning.15_sec_rewind')} className={`player-btn btn-rewind-small ${controls.disabled.rewind ? "disabled" : ""}`}
                        onClick={(e: any) => showControlBar && rewindQuarterHandler(e)}
                    >
                    </button>
                    <button
                        className={`player-btn btn-bookmark-small  ${controls.disabled.bookmark ? " disabled" : ""}`}
                        onClick={(e: any) => showControlBar && bookmarkHandler(e)}
                        title={t('e_learning.add_bookmark')}
                    >
                    </button>
                    <div className="timeline" onClick={(e: any) => e.stopPropagation()}>
                        <div className="bar">
                            <div
                                ref={progressBar}
                                className="progress-bar"
                            >
                            </div>
                            <div ref={bar} className="active-bar"></div>
                            <div ref={pointerTip} className={`pointer-info-wrap ${pointerProps.showTip ? 'show' : ''}`}>
                                {trimHours(pointerProps.time)}
                            </div>
                            {
                                canPlay && lesson
                                    ? bookmarks.map((bookmark: any, index: any) => {
                                        return <div
                                            key={index}
                                            style={{ left: getVideoBarPoint(bookmark.bookmark) }}
                                            className="bookmark-pin"
                                            title={bookmark.comment}
                                            onClick={() => editBookmark(index)}
                                        >
                                            <IconBookmarkPin title="" />
                                        </div>
                                    })
                                    : <></>
                            }
                        </div>
                        <div className="timer">
                            {/* {timer.currentTime} - {timer.duration} */}
                            {trimHours(timer.currentTime)} - {trimHours(timer.duration)}
                        </div>
                    </div>
                    <button
                        className={`player-btn btn-volume-small ${changeVolume.mute ? "mute" : ""}`}
                        onClick={(e: any) => {
                            e.stopPropagation();
                            if (!showControlBar) return;
                            //console.log(player.current.isMobile, !changeVolume.sliderOpen)
                            if (player.current.isMobile && !changeVolume.sliderOpen) {
                                volumeHandler("open");
                                return;
                            }
                            volumeHandler("mute");
                        }}
                        title={t('e_learning.volume')}
                        onMouseEnter={(e: any) => {
                            e.stopPropagation();
                            player.current.isMobile || volumeHandler("open");
                        }}
                        onMouseLeave={(e: any) => {
                            e.stopPropagation();
                            volumeHandler("close");
                        }}
                    >
                        {
                            changeVolume.sliderOpen
                                ? <div className="volume-wrap" onClick={(e: any) => e.stopPropagation()}>
                                    <Slider
                                        vertical
                                        value={changeVolume.mute ? 0 : changeVolume.volume * 100}
                                        style={{ margin: 0 }}
                                        onChange={(e: any) => {
                                            volumeHandler("change", e / 100)
                                        }}
                                    />
                                </div>
                                : <></>
                        }

                    </button>
                    <button
                        className={`player-btn btn-settings-small ${controls.disabled.settings ? "disabled" : ""}`}
                        onClick={(e: any) => showControlBar && settingsHandler(e, "open")}
                        title={t('e_learning.settings')}
                        onMouseEnter={(e: any) => {
                            e.stopPropagation();
                            player.current.isMobile || settingsHandler(e, "open");
                        }}
                        onMouseLeave={(e: any) => {
                            e.stopPropagation();
                            settingsHandler(e, "close");
                        }}
                    >
                        {
                            settingsOpen
                                ? <div className="settings-menu">
                                    <span className="setting-title">Kvalitāte</span><br />
                                    {
                                        qualities.map((quality: any) => {
                                            const active = quality === selectedQuality
                                            return <b
                                                className={`setting ${active ? 'active' : ''}`}
                                                onClick={(e: any) => {
                                                    if (active) return;
                                                    e.stopPropagation();
                                                    setVideoQuality(quality)
                                                }}
                                            >
                                                {quality}
                                            </b>
                                        })
                                    }
                                </div>
                                : <></>
                        }
                    </button>
                    {
                        navigator.userAgent.includes("iPhone")
                            ? <></>
                            : <button
                                className={`player-btn btn-fullScreen-small ${controls.disabled.fullscreen ? "disabled" : ""}`}
                                onClick={(e: any) => showControlBar && fullscreenHandler(e)}
                                title={t('e_learning.fullscreen') + `(${t('e_learning.disabled')})`}
                            >
                            </button>
                    }
                </div>
            </div>
        </div >
    </>
}