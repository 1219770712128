export const timePassedFromTotal = (
    timerTime?: string,
    totalMinutes?: number
): {
    minutes: number;
    seconds: number;
} => {
    if (!timerTime || !totalMinutes) {
        return {
            minutes: 0,
            seconds: 0,
        };
    }
    const minutes = timerTime.split(":")[0];
    const seconds = timerTime.split(":")[1];
    const secondsPassed = parseInt(seconds) === 0 ? 0 : 60 - parseInt(seconds);
    const minutesPassed =
        totalMinutes - parseInt(minutes) - (secondsPassed > 0 ? 1 : 0);
    return {
        minutes: minutesPassed,
        seconds: secondsPassed,
    };
};
